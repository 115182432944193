<template>
    <div
        class="text-left font-light whitespace-nowrap"
        :class="[
            backgroundClass,
            textColorClass,
            borderTopClass,
            borderBottomClass,
            { 'h-[2.5625rem]': props.isSiteCell },
        ]"
    >
        <div class="flex flex-row items-center gap-3 h-full pl-3 pr-1.5" :class="[borderRightClass]">
            <div v-if="props.checkboxStatus !== 'No-Checkbox'" class="flex">
                <Tooltip>
                    <template #trigger="tooltipProps">
                        <input
                            ref="checkBoxRef"
                            :aria-labelledby="labelId"
                            :aria-describedby="tooltipProps['aria-labelledby']"
                            type="checkbox"
                            :disabled="
                                props.checkboxStatus === 'Disabled-Checked' ||
                                props.checkboxStatus === 'Disabled-Not-Checked'
                            "
                            :checked="props.checkboxStatus === 'Checked' || props.checkboxStatus === 'Disabled-Checked'"
                            class="h-5 w-5 rounded border-gray-300 text-sky-700 focus:ring-sky-700"
                            :class="[
                                props.checkboxStatus === 'Disabled-Checked' ||
                                props.checkboxStatus === 'Disabled-Not-Checked'
                                    ? 'cursor-not-allowed'
                                    : 'cursor-pointer',
                                {
                                    'disabled:bg-gray-400 hover:text-gray-400':
                                        props.checkboxStatus === 'Disabled-Checked',
                                    'disabled:bg-gray-200 hover:text-gray-200':
                                        props.checkboxStatus === 'Disabled-Not-Checked',
                                },
                            ]"
                            @change="$emit('toggle-checkbox')"
                        />
                    </template>

                    <template #text>{{ props.checkboxTooltip }}</template>
                </Tooltip>
            </div>

            <Component
                :is="props.hasNestedLocations ? 'button' : 'div'"
                class="flex-1 flex flex-row items-center gap-3 h-full pr-2"
                :class="[
                    {
                        'focus-visible:ring-inset focus-visible:ring-offset-1 focus-visible:ring-sky-700 focus-visible:ring-2 outline-none active:ring-inset active:ring-offset-1 active:ring-sky-900 active:ring-2':
                            props.hasNestedLocations,
                    },
                ]"
                :style="paddingLeftStyle"
                :aria-expanded="props.hasNestedLocations ? props.isExpanded : undefined"
                @click="$emit('toggleLocationExpanded')"
                @keydown="$emit('keyPress', $event)"
            >
                <div
                    v-if="props.hasNestedLocations || props.errorMessage"
                    class="w-5 h-5 flex-shrink-0 flex items-center justify-center"
                >
                    <TriangleIcon
                        v-if="props.hasNestedLocations"
                        class="transition-transform"
                        :class="{ 'rotate-90': props.isExpanded }"
                    />
                </div>

                <div
                    :class="{
                        'w-5 h-5 flex-shrink-0 flex items-center justify-center':
                            props.checkboxStatus === 'No-Checkbox',
                    }"
                >
                    <slot />
                </div>

                <span class="flex items-center gap-3">
                    <span :id="labelId" :class="{ 'uppercase text-xs': props.isSiteCell }">
                        {{ props.label }}
                    </span>

                    <Tooltip v-if="props.errorMessage">
                        <template #trigger="tooltipProps">
                            <WarningIcon class="w-5 h-5" v-bind="tooltipProps" />
                        </template>

                        <template #text>
                            <div class="w-max">{{ props.errorMessage }}</div>
                        </template>
                    </Tooltip>
                </span>
            </Component>

            <div v-if="props.checkboxStatus === 'No-Checkbox'" class="w-5 h-5" />

            <Tooltip v-if="props.showResolvedConflictsIcon">
                <template #trigger="tooltipProps">
                    <div class="flex items-center">
                        <FlashOffIcon class="text-gray-500" :aria-labelledby="tooltipProps['aria-labelledby']" />
                    </div>
                </template>

                <template #text>
                    <i18n path="all-conflicts-resolved-tooltip" />
                </template>
            </Tooltip>

            <Tooltip v-if="props.showUnresolvedConflictsIcon">
                <template #trigger="tooltipProps">
                    <div
                        class="flex flex-row gap-0.5 h-4 text-xs items-center pl-3"
                        :aria-labelledby="tooltipProps['aria-labelledby']"
                    >
                        <span
                            :class="{
                                'text-red-700':
                                    props.status === 'has-conflicts-light' ||
                                    (props.isHovered && props.isConflictHovered),
                            }"
                        >
                            {{ props.unresolvedConflictsCount }}
                        </span>

                        <FlashIcon
                            :class="{
                                'text-red-600':
                                    props.status === 'has-conflicts-light' ||
                                    (props.isHovered && props.isConflictHovered),
                            }"
                        />
                    </div>
                </template>

                <template #text>
                    <i18n path="unresolved-conflicts-tooltip">
                        <template #unresolvedConflicts>
                            <span class="font-semibold">
                                {{ props.unresolvedConflictsCount }}
                            </span>
                        </template>
                    </i18n>
                </template>
            </Tooltip>

            <Tooltip v-if="props.linkTarget">
                <template #trigger="tooltipProps">
                    <RouterLink
                        v-bind="tooltipProps"
                        target="_blank"
                        :to="props.linkTarget"
                        class="border w-7 h-7 flex flex-row justify-center items-center rounded cursor-pointer focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-sky-700 focus-visible:z-10"
                        :class="[
                            locationLinkBackgroundColorClasses,
                            locationLinkTextColorClasses,
                            locationLinkBorderColorClasses,
                        ]"
                    >
                        <ExternalLinkIcon class="w-4 h-4" />
                    </RouterLink>
                </template>

                <template #text>
                    {{ $t('open-detail-page') }}
                </template>

                <template #header>{{ props.label }}</template>
            </Tooltip>
        </div>
    </div>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';
import { computed, ref, watchEffect } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { RouterLink } from 'vue-router';
import ExternalLinkIcon from '../../Icon/ExternalLinkIcon.vue';
import FlashIcon from '../../Icon/FlashIcon.vue';
import FlashOffIcon from '../../Icon/FlashOffIcon.vue';
import TriangleIcon from '../../Icon/TriangleIcon.vue';
import WarningIcon from '../../Icon/WarningIcon.vue';
import Tooltip from '../../Tooltip/Tooltip.vue';

type FirstColumnCellProps = {
    isSiteCell: boolean;
    status: 'has-conflicts-strong' | 'has-conflicts-light' | 'invalid-data' | 'ok';
    isHovered: boolean;
    isConflictHovered: boolean;
    hasNestedLocations: boolean;
    isExpanded: boolean;
    level: number;
    label: string;
    showResolvedConflictsIcon: boolean;
    showUnresolvedConflictsIcon: boolean;
    unresolvedConflictsCount: number;
    errorMessage?: string;
    linkTarget?: RouteLocationRaw;
    checkboxStatus:
        | 'Checked'
        | 'Partially-Checked'
        | 'Not-Checked'
        | 'Disabled-Checked'
        | 'Disabled-Not-Checked'
        | 'No-Checkbox';
    checkboxTooltip?: string;
};

const props = defineProps<FirstColumnCellProps>();

defineEmits<{
    (e: 'toggleLocationExpanded'): void;
    (e: 'keyPress', event: KeyboardEvent): void;
    (e: 'toggle-checkbox'): void;
}>();

const checkBoxRef = ref<HTMLInputElement>();
const labelId = nanoid();

watchEffect(() => {
    if (checkBoxRef.value) {
        checkBoxRef.value.indeterminate = props.checkboxStatus === 'Partially-Checked';
    }
});

const backgroundClass = computed(() => {
    if (props.isHovered) {
        if (props.isConflictHovered) {
            return 'bg-red-100';
        }

        return 'bg-blue-100';
    }

    if (props.status === 'has-conflicts-strong') {
        return 'bg-red-600';
    }

    if (props.status === 'has-conflicts-light') {
        return 'bg-red-100';
    }

    return 'bg-gray-50';
});

const textColorClass = computed(() => {
    if (props.errorMessage) {
        return 'text-red-700';
    }

    if (props.status === 'invalid-data') {
        return 'text-gray-400';
    }

    if (props.isHovered) {
        return 'text-gray-900';
    }

    if (props.status === 'has-conflicts-strong') {
        return 'text-white';
    }

    return 'text-gray-900';
});

const borderTopClass = computed(() => {
    if (props.isSiteCell) {
        if (props.status === 'has-conflicts-light') {
            return 'first-column-cell-border-top first-column-cell-border-top-1 first-column-cell-border-top-red-200';
        }

        return 'first-column-cell-border-top first-column-cell-border-top-1 first-column-cell-border-top-gray-200';
    }

    if (props.isHovered) {
        if (props.isConflictHovered) {
            return 'first-column-cell-border-top first-column-cell-border-top-1 first-column-cell-border-top-red-200';
        }

        return 'first-column-cell-border-top first-column-cell-border-top-1 first-column-cell-border-top-blue-200';
    }
});

const borderBottomClass = computed(() => {
    if (props.isSiteCell) {
        if (props.status === 'has-conflicts-light') {
            return 'first-column-cell-border-bottom first-column-cell-border-bottom-2 first-column-cell-border-bottom-red-200 first-column-cell-border-bottom-z-20';
        }

        return 'first-column-cell-border-bottom first-column-cell-border-bottom-2 first-column-cell-border-bottom-gray-300';
    }

    if (props.isHovered) {
        if (props.isConflictHovered) {
            return 'first-column-cell-border-bottom first-column-cell-border-bottom-1 first-column-cell-border-bottom-red-200';
        }

        return 'first-column-cell-border-bottom first-column-cell-border-bottom-1 first-column-cell-border-bottom-blue-200';
    }

    if (props.status === 'has-conflicts-light' || props.status === 'has-conflicts-strong') {
        return 'first-column-cell-border-bottom first-column-cell-border-bottom-1 first-column-cell-border-bottom-red-200';
    }

    return 'first-column-cell-border-bottom first-column-cell-border-bottom-1 first-column-cell-border-bottom-gray-200';
});

const borderRightClass = computed(() => {
    if (props.isHovered) {
        if (props.isConflictHovered) {
            return 'first-column-cell-border-right first-column-cell-border-right-2-max-height first-column-cell-border-right-red-600';
        }

        return 'first-column-cell-border-right first-column-cell-border-right-2-max-height first-column-cell-border-right-blue-400';
    }

    if (props.status === 'has-conflicts-strong') {
        return 'first-column-cell-border-right first-column-cell-border-right-2 first-column-cell-border-right-red-800';
    }

    if (props.status === 'has-conflicts-light') {
        if (props.isSiteCell) {
            return 'first-column-cell-border-right first-column-cell-border-right-2-max-height first-column-cell-border-right-red-600 first-column-cell-border-right-z-20';
        }

        return 'first-column-cell-border-right first-column-cell-border-right-2 first-column-cell-border-right-red-600';
    }
});

const paddingLeftStyle = computed(() => {
    return `padding-left: ${props.level * 2}rem;`;
});

const locationLinkBackgroundColorClasses = computed(() => {
    if (props.isHovered) {
        if (props.isConflictHovered) {
            return 'bg-gray-50 hover:bg-red-600 active:bg-red-900';
        }

        return 'bg-gray-50 hover:bg-sky-700 active:bg-sky-900';
    }

    if (props.status === 'has-conflicts-strong' || props.status === 'has-conflicts-light') {
        return 'bg-gray-50 hover:bg-red-600 active:bg-red-900';
    }

    return 'bg-gray-50 hover:bg-gray-600 active:bg-gray-900';
});

const locationLinkTextColorClasses = computed(() => {
    return 'text-gray-700 hover:text-gray-50 active:text-gray-50';
});

const locationLinkBorderColorClasses = computed(() => {
    if (props.isHovered) {
        if (props.isConflictHovered) {
            return 'border-red-300 active:border-red-600';
        }

        return 'border-blue-300 active:border-blue-500';
    }

    if (props.status === 'has-conflicts-strong') {
        return 'border-red-800';
    }

    if (props.status === 'has-conflicts-light') {
        return 'border-red-300';
    }

    return 'border-gray-300';
});
</script>

<style scoped>
/**
 * These are needed to avoid ugly triangular intersections of 1px and 2px wide
 * borders which can happen due to border-collapse: separate; being used.
 * border-collapse: separate; is required to implement sticky columns/rows
 * without losing the border when cells are scrolled under the sticky ones.
 */

.first-column-cell-border-top,
.first-column-cell-border-bottom,
.first-column-cell-border-right {
    position: relative;
}

.first-column-cell-border-top-1::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    z-index: 10;
}
.first-column-cell-border-top-red-200::before {
    background-color: theme('colors.red.200');
}
.first-column-cell-border-top-red-800::before {
    background-color: theme('colors.red.800');
}
.first-column-cell-border-top-blue-200::before {
    background-color: theme('colors.blue.200');
}
.first-column-cell-border-top-gray-200::before {
    background-color: theme('colors.gray.200');
}

.first-column-cell-border-bottom-1::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
}
.first-column-cell-border-bottom-2::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
}
.first-column-cell-border-bottom-red-200::after {
    background-color: theme('colors.red.200');
}
.first-column-cell-border-bottom-red-800::after {
    background-color: theme('colors.red.800');
}
.first-column-cell-border-bottom-blue-200::after {
    background-color: theme('colors.blue.200');
}
.first-column-cell-border-bottom-gray-200::after {
    background-color: theme('colors.gray.200');
}
.first-column-cell-border-bottom-gray-300::after {
    background-color: theme('colors.gray.300');
}
.first-column-cell-border-bottom-z-10::after {
    z-index: 10;
}
.first-column-cell-border-bottom-z-20::after {
    z-index: 20;
}

.first-column-cell-border-right-2::after {
    content: '';
    position: absolute;
    top: 0;
    right: -2px;
    width: 2px;
    height: calc(100%);
}
.first-column-cell-border-right-2-max-height::after {
    content: '';
    position: absolute;
    top: -1px;
    right: -2px;
    width: 2px;
    height: calc(100% + 2px);
}
.first-column-cell-border-right-red-600::after {
    background-color: theme('colors.red.600');
}
.first-column-cell-border-right-red-800::after {
    background-color: theme('colors.red.800');
}
.first-column-cell-border-right-blue-400::after {
    background-color: theme('colors.blue.400');
}
.first-column-cell-border-right-z-10::after {
    z-index: 10;
}
.first-column-cell-border-right-z-20::after {
    z-index: 20;
}
</style>
