<template>
    <div class="flex flex-row items-center" :class="[backgroundClass, textColorClass, borderVerticalClasses]">
        <div v-if="props.checkboxStatus !== 'No-Checkbox'" class="flex pl-3 pr-1.5">
            <Tooltip>
                <template #trigger="tooltipProps">
                    <input
                        ref="checkBoxRef"
                        :aria-labelledby="labelId"
                        :aria-describedby="tooltipProps['aria-labelledby']"
                        type="checkbox"
                        :disabled="
                            props.checkboxStatus === 'Disabled-Checked' ||
                            props.checkboxStatus === 'Disabled-Not-Checked'
                        "
                        :checked="props.checkboxStatus === 'Checked' || props.checkboxStatus === 'Disabled-Checked'"
                        class="h-5 w-5 rounded border-gray-300 text-sky-700 focus:ring-sky-700"
                        :class="[
                            props.checkboxStatus === 'Disabled-Checked' ||
                            props.checkboxStatus === 'Disabled-Not-Checked'
                                ? 'cursor-not-allowed'
                                : 'cursor-pointer',
                            {
                                'disabled:bg-gray-400': props.checkboxStatus === 'Disabled-Checked',
                                'disabled:bg-gray-200': props.checkboxStatus === 'Disabled-Not-Checked',
                            },
                        ]"
                        @change="$emit('toggle-checkbox')"
                    />
                </template>

                <template #text>{{ props.checkboxTooltip }}</template>
            </Tooltip>
        </div>

        <button
            class="pr-1.5 text-xxs font-bold leading-[1.625rem] w-full"
            :class="[focusClasses, activeClasses, props.checkboxStatus === 'No-Checkbox' ? 'pl-3' : 'pl-1.5']"
            type="button"
            @click="$emit('toggle-section-expanded')"
            @mouseover="$emit('section-hover')"
        >
            <div class="flex flex-row items-center gap-3" :class="borderRightClass">
                <div class="w-5 h-5 flex-shrink-0 flex items-center justify-center">
                    <TriangleIcon class="transition-transform" :class="{ 'rotate-90': props.isExpanded }" />
                </div>

                <div class="w-5 h-5 flex-shrink-0 flex items-center justify-center">
                    <slot />
                </div>

                <div class="flex flex-row justify-between w-full gap-3 uppercase">
                    <p class="whitespace-nowrap">
                        {{ props.label }}
                        <span class="tabular-nums">({{ props.allocationCount }})</span>
                    </p>

                    <div v-if="props.checkboxStatus === 'No-Checkbox'" class="flex-shrink-0 w-5 h-5" />

                    <div v-if="props.status === 'has-conflicts'" class="normal-case flex">
                        <Tooltip v-if="props.unresolvedConflictsCount === 0">
                            <template #trigger="tooltipProps">
                                <div class="flex items-center normal-case">
                                    <FlashOffIcon
                                        class="text-gray-500"
                                        :aria-labelledby="tooltipProps['aria-labelledby']"
                                    />
                                </div>
                            </template>

                            <template #text>
                                <div class="w-max">
                                    <i18n path="all-conflicts-resolved-tooltip" />
                                </div>
                            </template>
                        </Tooltip>

                        <Tooltip v-if="props.unresolvedConflictsCount > 0">
                            <template #trigger="tooltipProps">
                                <div
                                    class="flex flex-row gap-0.5 text-xs font-normal items-center"
                                    :class="textColorClass"
                                    :aria-labelledby="tooltipProps['aria-labelledby']"
                                >
                                    <span class="pl-1.5" :class="[props.isHovered ? 'text-white' : 'text-red-600']">
                                        {{ props.unresolvedConflictsCount }}
                                    </span>

                                    <FlashIcon :class="[props.isHovered ? 'text-white' : 'text-red-600']" />
                                </div>
                            </template>

                            <template #text>
                                <div class="w-max">
                                    <i18n path="unresolved-conflicts-tooltip">
                                        <template #unresolvedConflicts>
                                            <span class="font-semibold">
                                                {{ props.unresolvedConflictsCount }}
                                            </span>
                                        </template>
                                    </i18n>
                                </div>
                            </template>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </button>
    </div>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';
import { computed, ref, watchEffect } from 'vue';
import FlashIcon from '../../Icon/FlashIcon.vue';
import FlashOffIcon from '../../Icon/FlashOffIcon.vue';
import TriangleIcon from '../../Icon/TriangleIcon.vue';
import Tooltip from '../../Tooltip/Tooltip.vue';
import type { SectionStatus } from '../TimelineView.types';

type SectionRowProps = {
    label: string;
    isExpanded: boolean;
    isHovered: boolean;
    status: SectionStatus;
    unresolvedConflictsCount: number;
    allocationCount: number;
    checkboxStatus:
        | 'Checked'
        | 'Partially-Checked'
        | 'Not-Checked'
        | 'Disabled-Checked'
        | 'Disabled-Not-Checked'
        | 'No-Checkbox';
    checkboxTooltip?: string;
};

const props = defineProps<SectionRowProps>();

defineEmits<{
    (e: 'toggle-section-expanded'): void;
    (e: 'toggle-checkbox'): void;
    (e: 'section-hover'): void;
}>();

const backgroundClass = computed(() => {
    if (props.isHovered) {
        if (props.status === 'has-conflicts') {
            return 'bg-red-600';
        }

        return 'bg-gray-600';
    }

    if (props.status === 'has-conflicts') {
        return 'bg-red-100';
    }

    return 'bg-gray-200';
});

const textColorClass = computed(() => {
    if (props.isHovered) {
        if (props.status === 'has-conflicts') {
            return 'text-white';
        }

        return 'text-gray-50';
    }

    return 'text-gray-900';
});

const borderVerticalClasses = computed(() => {
    if (props.isHovered) {
        if (props.status === 'has-conflicts') {
            return 'section-row-border-top section-row-border-top-red-800 section-row-border-top-z-20 section-row-border-bottom section-row-border-bottom-red-800 section-row-border-bottom-z-20';
        }

        return 'section-row-border-top section-row-border-top-gray-600 section-row-border-top-z-20 section-row-border-bottom section-row-border-bottom-gray-600 section-row-border-bottom-z-20';
    }

    if (props.status === 'has-conflicts') {
        return 'section-row-border-top section-row-border-top-red-200 section-row-border-top-z-20 section-row-border-bottom section-row-border-bottom-red-200 section-row-border-bottom-z-20';
    }

    return 'section-row-border-top section-row-border-top-gray-300 section-row-border-top-z-10 section-row-border-bottom section-row-border-bottom-gray-300 section-row-border-bottom-z-10';
});

const borderRightClass = computed(() => {
    if (props.isHovered) {
        if (props.status === 'has-conflicts') {
            return 'section-row-border-right-2-max-height section-row-border-right-red-800';
        }

        return 'section-row-border-right-2-max-height section-row-border-right-gray-800';
    }

    if (props.status === 'has-conflicts') {
        return 'section-row-border-right-2-max-height section-row-border-right-red-600';
    }
});

const focusClasses = computed(() => {
    return 'focus-visible:ring-inset focus-visible:ring-offset-1 focus-visible:ring-sky-700 focus-visible:ring-2 outline-none';
});

const activeClasses = computed(() => {
    return 'active:ring-inset active:ring-offset-1 active:ring-sky-700 active:ring-2';
});

const checkBoxRef = ref<HTMLInputElement>();
const labelId = nanoid();

watchEffect(() => {
    if (checkBoxRef.value) {
        checkBoxRef.value.indeterminate = props.checkboxStatus === 'Partially-Checked';
    }
});
</script>

<style scoped>
.section-row-border-top,
.section-row-border-bottom,
.section-row-border-right {
    position: relative;
}

.section-row-border-top::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
}

.section-row-border-bottom::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
}

.section-row-border-top-z-10::before {
    z-index: 10;
}

.section-row-border-top-z-20::before {
    z-index: 20;
}

.section-row-border-bottom-z-10::after {
    z-index: 10;
}

.section-row-border-bottom-z-20::after {
    z-index: 20;
}

.section-row-border-top-gray-300::before {
    background-color: theme('colors.gray.300');
}

.section-row-border-top-gray-600::before {
    background-color: theme('colors.gray.600');
}

.section-row-border-top-red-200::before {
    background-color: theme('colors.red.200');
}

.section-row-border-top-red-600::before {
    background-color: theme('colors.red.600');
}

.section-row-border-top-red-800::before {
    background-color: theme('colors.red.800');
}

.section-row-border-bottom-gray-300::after {
    background-color: theme('colors.gray.300');
}

.section-row-border-bottom-gray-600::after {
    background-color: theme('colors.gray.600');
}

.section-row-border-bottom-red-200::after {
    background-color: theme('colors.red.200');
}

.section-row-border-bottom-red-600::after {
    background-color: theme('colors.red.600');
}

.section-row-border-bottom-red-800::after {
    background-color: theme('colors.red.800');
}

.section-row-border-right-2-max-height::after {
    content: '';
    position: absolute;
    top: -1px;
    right: -2px;
    width: 2px;
    height: calc(100% + 2px);
}

.section-row-border-right-gray-800::after {
    background-color: theme('colors.gray.800');
}

.section-row-border-right-red-600::after {
    background-color: theme('colors.red.600');
}

.section-row-border-right-red-800::after {
    background-color: theme('colors.red.800');
}
</style>
