<template>
    <div class="flex flex-col items-center" :class="{ 'z-50 drop-shadow-md': props.isChangeTypePopupOpen }">
        <div
            v-if="props.isChangeTypePopupOpen"
            ref="changeTypePopupRef"
            class="rounded-md border-2 p-2 connect-bar-inverted-border-radius-change-phase-button z-10 after:w-[1.125rem]"
            :class="[borderColor, backgroundColor, afterBackgroundColor, fillColor]"
            :style="floatingStyles"
        >
            <!-- Left inverted border radius -->
            <svg
                class="absolute -bottom-1.5 right-1/2 -translate-x-[0.5625rem]"
                viewBox="0 0 100 100"
                width="4"
                height="4"
            >
                <mask id="invertedBorderRadiusLeft">
                    <rect x="0" y="0" width="100" height="100" fill="white" />
                    <circle cx="0" cy="100" r="100" fill="black" />
                </mask>

                <rect x="0" y="0" width="100" height="100" mask="url(#invertedBorderRadiusLeft)" />
            </svg>

            <!-- Right inverted border radius -->
            <svg
                class="absolute -bottom-1.5 left-1/2 translate-x-[0.5625rem]"
                viewBox="0 0 100 100"
                width="4"
                height="4"
            >
                <mask id="invertedBorderRadiusRight">
                    <rect x="0" y="0" width="100" height="100" fill="white" />
                    <circle cx="100" cy="100" r="100" fill="black" />
                </mask>

                <rect x="0" y="0" width="100" height="100" mask="url(#invertedBorderRadiusRight)" />
            </svg>

            <!-- Filler left -->
            <svg class="absolute -bottom-1 right-1/2 -translate-x-[0.5rem]" viewBox="0 0 100 100" width="1" height="1">
                <rect x="0" y="0" width="100" height="100" />
            </svg>

            <!-- Filler right -->
            <svg class="absolute -bottom-1 left-1/2 translate-x-[0.5rem]" viewBox="0 0 100 100" width="1" height="1">
                <rect x="0" y="0" width="100" height="100" />
            </svg>

            <ChangeTypePopupContent
                :phase-type="props.phaseType"
                :specializations="props.specializations"
                :selected-specialization="props.selectedSpecialization"
                @change-specialization="(specialization) => emit('change-specialization', specialization)"
                @close-popup="
                    triggerHideTooltip();
                    $emit('toggle-change-phase-button');
                "
            />
        </div>

        <Tooltip placement="bottom" :is-teleported="true">
            <template #trigger="tooltipProps">
                <button
                    ref="changeTypeButtonRef"
                    :aria-labelledby="tooltipProps['aria-labelledby']"
                    type="button"
                    class="text-gray-700 h-[1.125rem] w-[1.125rem] rounded-sm flex place-content-center place-items-center border focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-700 drop-shadow-[0_0_0.0625rem_rgba(0,0,0,0.3)]"
                    :class="[colorClasses]"
                    @click="
                        triggerHideTooltip();
                        $emit('toggle-change-phase-button');
                    "
                >
                    <ReplaceIcon v-if="props.changePhaseStatus === 'Open-Popup'" class="h-3 w-3" />
                    <CloseIcon v-if="props.changePhaseStatus === 'Close-Popup'" class="h-3 w-3" />
                </button>
            </template>

            <template #text>
                <div v-if="props.isChangeTypePopupOpen" class="w-max">
                    {{ $t('close-change-phase-type-popup') }}
                </div>

                <div v-if="!props.isChangeTypePopupOpen" class="w-max">
                    {{ $t('open-change-phase-type-popup') }}
                </div>
            </template>
        </Tooltip>

        <div
            v-if="Boolean(props.selectedSpecialization) || props.hasTemporaryChangeMarker"
            class="w-[0.1875rem] h-[0.1875rem]"
            :class="backgroundColorClass"
        />

        <div v-if="Boolean(props.selectedSpecialization) || props.hasTemporaryChangeMarker">
            <div
                class="size-4 flex items-center justify-center rounded-sm relative"
                :class="[backgroundColorClass, textColorClass]"
            >
                <div
                    v-if="props.hasTemporaryChangeMarker"
                    class="w-[0.375rem] h-[0.375rem] absolute bg-red-700 rounded-full right-[0.0625rem] top-[0.0625rem]"
                />

                <!-- Inside icons -->
                <ClothesHangerIcon v-if="specializationIcon === 'ClothesHanger'" class="size-3" />
                <DoorBackIcon v-if="specializationIcon === 'DoorBack'" class="size-3" />
                <GroupIcon v-if="specializationIcon === 'Group'" class="size-3" />
                <InfoIcon v-if="specializationIcon === 'Info'" class="size-3" />
                <PackageIcon v-if="specializationIcon === 'Package'" class="size-3" />
                <ParkingIcon v-if="specializationIcon === 'Parking'" class="size-3" />
                <RestaurantIcon v-if="specializationIcon === 'Restaurant'" class="size-3" />
                <TruckIcon v-if="specializationIcon === 'Truck'" class="size-3" />
                <WalkingIcon v-if="specializationIcon === 'Walking'" class="size-3" />

                <!-- Outside icons -->
                <CenterPointIcon v-if="specializationIcon === 'CenterPoint'" class="size-3" />
                <DirectionsRunIcon v-if="specializationIcon === 'DirectionsRun'" class="size-3" />
                <StoreFrontIcon v-if="specializationIcon === 'StoreFront'" class="size-3" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { autoUpdate, offset, useFloating } from '@floating-ui/vue';
import { computed, provide, ref } from 'vue';
import CenterPointIcon from '../../Icon/CenterPointIcon.vue';
import CloseIcon from '../../Icon/CloseIcon.vue';
import ClothesHangerIcon from '../../Icon/ClothesHangerIcon.vue';
import DirectionsRunIcon from '../../Icon/DirectionsRunIcon.vue';
import DoorBackIcon from '../../Icon/DoorBackIcon.vue';
import GroupIcon from '../../Icon/GroupIcon.vue';
import InfoIcon from '../../Icon/InfoIcon.vue';
import PackageIcon from '../../Icon/PackageIcon.vue';
import ParkingIcon from '../../Icon/ParkingIcon.vue';
import ReplaceIcon from '../../Icon/ReplaceIcon.vue';
import RestaurantIcon from '../../Icon/RestaurantIcon.vue';
import StoreFrontIcon from '../../Icon/StoreFrontIcon.vue';
import TruckIcon from '../../Icon/TruckIcon.vue';
import WalkingIcon from '../../Icon/WalkingIcon.vue';
import Tooltip from '../../Tooltip/Tooltip.vue';
import { triggerHideTooltipKey } from '../../Tooltip/injectionKeys';
import { PhaseType } from '../Timeline.types';
import { mapSpecializationsTypesToIcons, SpecializationType } from '../allocation/SpecializationMappings';
import {
    getActiveBackgroundColorClassForType,
    getActiveBorderColorClassForType,
    getBorderColorClassForType,
    getFillColorClassForType,
    getLightBackgroundColorClassForType,
    getStrongBackgroundColorAfterClassForType,
    getStrongBackgroundColorClassForType,
    getTextColorClassForType,
} from '../allocation/shared';
import ChangeTypePopupContent from './ChangeTypePopupContent.vue';

type ChangePhaseButtonProps = {
    phaseType: PhaseType;
    changePhaseStatus: 'Open-Popup' | 'Close-Popup' | 'Dont-Show';
    isChangeTypePopupOpen: boolean;
    specializations: (SpecializationType | null)[];
    selectedSpecialization: SpecializationType | null;
    hasTemporaryChangeMarker: boolean;
};

const props = defineProps<ChangePhaseButtonProps>();

const colorClasses = computed(() => {
    const backgroundColorClass = getLightBackgroundColorClassForType(props.phaseType);
    const hoverBackgroundColorClass = getStrongBackgroundColorClassForType(props.phaseType);
    const activeBackgroundColorClass = getActiveBackgroundColorClassForType(props.phaseType);
    const borderColorClass = getBorderColorClassForType(props.phaseType);
    const borderActiveColorClass = getActiveBorderColorClassForType(props.phaseType);

    return `
        ${backgroundColorClass} ${borderColorClass} text-gray-700
        hover:${hoverBackgroundColorClass} hover:text-gray-50
        ${activeBackgroundColorClass} active:text-gray-50 ${borderActiveColorClass}
    `;
});

const backgroundColorClass = computed(() => getStrongBackgroundColorClassForType(props.phaseType));
const textColorClass = computed(() => getTextColorClassForType(props.phaseType));

const emit = defineEmits<{
    (e: 'toggle-change-phase-button'): void;
    (e: 'change-specialization', specialization: SpecializationType | null): void;
}>();

// This is used to force close the Tooltip after clicking the button
const triggerHideTooltipKeyCounter = ref(0);
provide(triggerHideTooltipKey, triggerHideTooltipKeyCounter);

function triggerHideTooltip() {
    triggerHideTooltipKeyCounter.value = triggerHideTooltipKeyCounter.value + 1;
}

const borderColor = computed(() => getBorderColorClassForType(props.phaseType));
const backgroundColor = computed(() => getLightBackgroundColorClassForType(props.phaseType));
const afterBackgroundColor = computed(() => getStrongBackgroundColorAfterClassForType(props.phaseType));
const fillColor = computed(() => getFillColorClassForType(props.phaseType));

const changeTypeButtonRef = ref();
const changeTypePopupRef = ref();

const { floatingStyles } = useFloating(changeTypeButtonRef, changeTypePopupRef, {
    open: props.isChangeTypePopupOpen,
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [offset(0)],
});

const specializationIcon = computed(() => mapSpecializationsTypesToIcons(props.selectedSpecialization));
</script>

<style scoped>
.connect-bar-inverted-border-radius-change-phase-button {
    position: relative;
}

.connect-bar-inverted-border-radius-change-phase-button::after {
    content: '';
    position: absolute;
    height: 1px;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%);
}
</style>
