<template>
    <div
        class="w-[2.1875rem] flex flex-col items-center drop-shadow-[0_0_0.0625rem_rgba(0,0,0,0.3)] z-40"
        :class="{
            'z-50': props.isChangeTypePopupOpen || isReplacePhaseTooltipHovered,
            'pointer-events-none': !props.isChangeTypePopupOpen,
        }"
    >
        <div
            v-if="props.isChangeTypePopupOpen"
            ref="changeTypePopupRef"
            class="rounded-md border-2 p-2 connect-bar-inverted-border-radius z-10"
            :class="[
                borderColor,
                backgroundColor,
                afterBackgroundColor,
                fillColor,
                [props.phaseType === 'Durchführung' ? 'after:w-8' : 'after:w-4'],
            ]"
            :style="floatingStyles"
        >
            <!-- Left inverted border radius -->
            <svg
                class="absolute -bottom-1.5 right-1/2"
                :class="[props.phaseType === 'Durchführung' ? '-translate-x-4' : '-translate-x-2']"
                viewBox="0 0 100 100"
                width="4"
                height="4"
            >
                <mask id="invertedBorderRadiusLeft">
                    <rect x="0" y="0" width="100" height="100" fill="white" />
                    <circle cx="0" cy="100" r="100" fill="black" />
                </mask>

                <rect x="0" y="0" width="100" height="100" mask="url(#invertedBorderRadiusLeft)" />
            </svg>

            <!-- Right inverted border radius -->
            <svg
                class="absolute -bottom-1.5 left-1/2"
                :class="[props.phaseType === 'Durchführung' ? 'translate-x-4' : 'translate-x-2']"
                viewBox="0 0 100 100"
                width="4"
                height="4"
            >
                <mask id="invertedBorderRadiusRight">
                    <rect x="0" y="0" width="100" height="100" fill="white" />
                    <circle cx="100" cy="100" r="100" fill="black" />
                </mask>

                <rect x="0" y="0" width="100" height="100" mask="url(#invertedBorderRadiusRight)" />
            </svg>

            <ChangeTypePopupContent
                :phase-type="props.phaseType"
                :specializations="props.allowedSpecializations"
                :selected-specialization="props.selectedSpecialization"
                @change-specialization="(specialization) => emit('change-specialization', specialization)"
                @close-popup="
                    triggerHideTooltip();
                    emit('toggle-change-phase-type');
                "
            />
        </div>

        <div class="relative">
            <div
                v-if="props.direction === 'Both' && props.cellSpanBefore > 0"
                class="absolute flex flex-row items-center top-0 -left-[1.71875rem]"
            >
                <div
                    class="h-[1.125rem] w-[1.125rem] flex place-content-center gap-1 rounded-sm p-[0.0625rem] pointer-events-auto"
                    :class="[backgroundColorClass, iconColorClass]"
                >
                    <Tooltip>
                        <template #trigger="tooltipProps">
                            <button
                                v-bind="tooltipProps"
                                :aria-disabled="props.isDecreaseButtonDisabled"
                                type="button"
                                class="text-xs h-4 w-4 flex place-content-center text-center rounded-[0.0625rem] focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-700"
                                :class="[
                                    props.isDecreaseButtonDisabled
                                        ? buttonDisabledClasses
                                        : props.isDecreasingDeleting
                                          ? deleteButtonClasses
                                          : buttonClasses,
                                ]"
                                @mouseover="send('Focus additional left minus button')"
                                @mouseleave="send('Blur left button')"
                                @focus="send('Focus additional left minus button')"
                                @blur="send('Blur left button')"
                                @click="
                                    props.cellSpanBefore === 1
                                        ? send('Remove temporary phase left')
                                        : send('Click left button')
                                "
                            >
                                {{ $t('minus-one') }}
                            </button>
                        </template>

                        <template #text>
                            <div class="w-full min-w-max text-center">
                                {{ $t('phase-reduce-length-left') }}
                            </div>
                        </template>

                        <template #header>
                            <div class="w-full min-w-max text-center">
                                {{ props.phaseType }}
                            </div>
                        </template>
                    </Tooltip>
                </div>

                <div class="w-[0.59375rem] h-[0.1875rem]" :class="backgroundColorClass" />
            </div>

            <div
                class="rounded-sm flex flex-row items-center gap-[0.0625rem] p-[0.0625rem] pointer-events-auto"
                :class="[backgroundColorClass]"
            >
                <Tooltip>
                    <template #trigger="tooltipProps">
                        <button
                            v-bind="tooltipProps"
                            :aria-disabled="props.isDecreaseButtonDisabled"
                            type="button"
                            class="text-xs h-4 w-4 flex place-content-center text-center rounded-[0.0625rem] focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-700"
                            :class="[
                                props.isDecreaseButtonDisabled
                                    ? buttonDisabledClasses
                                    : props.isDecreasingDeleting
                                      ? deleteButtonClasses
                                      : buttonClasses,
                            ]"
                            @mouseover="send('Focus left button')"
                            @mouseleave="send('Blur left button')"
                            @focus="send('Focus left button')"
                            @blur="send('Blur left button')"
                            @click="send('Click left button')"
                        >
                            <DeleteForeverIcon v-if="props.isDecreasingDeleting" class="h3 w-3" />
                            <template v-else-if="props.direction === 'Both'">{{ $t('plus-one') }}</template>
                            <template v-else>{{ $t('minus-one') }}</template>
                        </button>
                    </template>

                    <template #text>
                        <div v-if="props.isDecreaseButtonDisabled" class="w-full min-w-max text-center">
                            {{ $t('phase-already-marked-for-deletion') }}
                        </div>

                        <div v-else-if="props.isDecreasingDeleting" class="w-full min-w-max text-center">
                            {{ $t('phase-mark-for-deletion') }}
                        </div>

                        <div v-else-if="props.direction === 'Both'" class="w-full min-w-max text-center">
                            {{ $t('phase-increase-length-left') }}
                        </div>

                        <div v-else class="w-full min-w-max text-center">
                            {{ $t('phase-reduce-length') }}
                        </div>
                    </template>

                    <template #header>
                        <div class="w-full text-center">{{ props.phaseType }}</div>
                    </template>
                </Tooltip>

                <Tooltip>
                    <template #trigger="tooltipProps">
                        <button
                            v-bind="tooltipProps"
                            type="button"
                            class="text-xs h-4 w-4 text-center rounded-[0.0625rem] focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-700"
                            :class="[buttonClasses]"
                            @mouseover="send('Focus right button')"
                            @mouseleave="send('Blur right button')"
                            @focus="send('Focus right button')"
                            @blur="send('Blur right button')"
                            @click="send('Click right button')"
                        >
                            {{ $t('plus-one') }}
                        </button>
                    </template>

                    <template #text>
                        <div v-if="props.direction === 'Both'" class="w-full min-w-max text-center">
                            {{ $t('phase-increase-length-right') }}
                        </div>

                        <div v-else class="w-full min-w-max text-center">
                            {{ $t('phase-increase-length') }}
                        </div>
                    </template>

                    <template #header>
                        <div class="w-full min-w-max text-center">
                            {{ props.phaseType }}
                        </div>
                    </template>
                </Tooltip>
            </div>

            <div
                v-if="props.direction === 'Both' && props.cellSpanAfter > 0"
                class="absolute flex flex-row items-center top-0 -right-[1.71875rem]"
            >
                <div class="w-[0.59375rem] h-[0.1875rem]" :class="backgroundColorClass" />

                <div
                    class="h-[1.125rem] w-[1.125rem] flex place-content-center gap-1 rounded-sm p-[0.0625rem] pointer-events-auto"
                    :class="[backgroundColorClass, iconColorClass]"
                >
                    <Tooltip>
                        <template #trigger="tooltipProps">
                            <button
                                v-bind="tooltipProps"
                                :aria-disabled="props.isDecreaseButtonDisabled"
                                type="button"
                                class="text-xs h-4 w-4 flex place-content-center text-center rounded-[0.0625rem] focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-700"
                                :class="[
                                    props.isDecreaseButtonDisabled
                                        ? buttonDisabledClasses
                                        : props.isDecreasingDeleting
                                          ? deleteButtonClasses
                                          : buttonClasses,
                                ]"
                                @mouseover="send('Focus additional right minus button')"
                                @mouseleave="send('Blur right button')"
                                @focus="send('Focus additional right minus button')"
                                @blur="send('Blur right button')"
                                @click="
                                    props.cellSpanAfter === 1
                                        ? send('Remove temporary phase right')
                                        : send('Click right button')
                                "
                            >
                                {{ $t('minus-one') }}
                            </button>
                        </template>

                        <template #text>
                            <div class="w-full min-w-max text-center">
                                {{ $t('phase-reduce-length-right') }}
                            </div>
                        </template>

                        <template #header>
                            <div class="w-full min-w-max text-center">
                                {{ props.phaseType }}
                            </div>
                        </template>
                    </Tooltip>
                </div>
            </div>
        </div>

        <div class="w-[0.1875rem] h-[0.1875rem]" :class="backgroundColorClass" />

        <div
            class="h-4 w-4 flex place-content-center gap-1 rounded-b-sm p-0.5"
            :class="[
                props.direction === 'Both' ? 'w-[2.0625rem]' : 'w-4',
                backgroundColorClass,
                iconColorClass,
                { 'rounded-t-sm': !props.isChangeTypePopupOpen },
            ]"
        >
            <InsertLeftIcon
                v-if="
                    state.matches('Left direction.Default') ||
                    state.matches('Both directions.Default') ||
                    state.matches('Both directions.+1 right focused') ||
                    state.matches('Both directions.-1 right focused')
                "
                class="h-3 w-3"
            />
            <InsertLeftFilledIcon
                v-if="state.matches('Left direction.+1 focused') || state.matches('Both directions.+1 left focused')"
                class="h-3 w-3"
            />
            <InsertLeftRemovedIcon
                v-if="state.matches('Left direction.-1 focused') || state.matches('Both directions.-1 left focused')"
                class="h-3 w-3"
            />

            <DeleteForeverIcon
                v-if="state.matches('Left direction.Delete focused') || state.matches('Right direction.Delete focused')"
                class="h-3 w-3"
            />
            <ReplaceIcon v-if="state.matches('Replace focused')" class="h-3 w-3" />

            <InsertRightIcon
                v-if="
                    state.matches('Right direction.Default') ||
                    state.matches('Both directions.Default') ||
                    state.matches('Both directions.+1 left focused') ||
                    state.matches('Both directions.-1 left focused')
                "
                class="h-3 w-3"
            />
            <InsertRightFilledIcon
                v-if="state.matches('Right direction.+1 focused') || state.matches('Both directions.+1 right focused')"
                class="h-3 w-3"
            />
            <InsertRightRemovedIcon
                v-if="state.matches('Right direction.-1 focused') || state.matches('Both directions.-1 right focused')"
                class="h-3 w-3"
            />
        </div>

        <div v-if="props.hasChangePhaseButton" class="w-[0.1875rem] h-[0.1875rem]" :class="backgroundColorClass" />

        <div
            v-if="props.hasChangePhaseButton"
            class="h-[1.125rem] w-[1.125rem] flex place-content-center rounded-sm p-[0.0625rem] pointer-events-auto"
            :class="[backgroundColorClass]"
        >
            <Tooltip placement="bottom">
                <template #trigger="tooltipProps">
                    <button
                        v-bind="tooltipProps"
                        ref="changeTypeButtonRef"
                        class="w-full p-0.5 flex rounded-[0.0625rem] focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-700"
                        :class="[buttonClasses]"
                        type="button"
                        @mouseover="send('Focus replace button'), (isReplacePhaseTooltipHovered = true)"
                        @mouseleave="send('Blur replace button'), (isReplacePhaseTooltipHovered = false)"
                        @focus="send('Focus replace button')"
                        @blur="send('Blur replace button'), (isReplacePhaseTooltipHovered = false)"
                        @click="send('Click replace button'), (isReplacePhaseTooltipHovered = false)"
                    >
                        <ReplaceIcon v-if="props.changePhaseStatus === 'Open-Popup'" class="h-3 w-3" />
                        <CloseIcon v-if="props.changePhaseStatus === 'Close-Popup'" class="h-3 w-3" />
                    </button>
                </template>

                <template #text>
                    <div class="w-full min-w-max text-center">
                        <template v-if="props.changePhaseStatus === 'Open-Popup'">
                            {{ $t('open-change-phase-type-popup') }}
                        </template>

                        <template v-if="props.changePhaseStatus === 'Close-Popup'">
                            {{ $t('close-change-phase-type-popup') }}
                        </template>
                    </div>
                </template>

                <template #header>
                    <div class="w-full min-w-max text-center">
                        {{ props.phaseType }}
                    </div>
                </template>
            </Tooltip>
        </div>

        <div
            v-if="Boolean(props.selectedSpecialization) || props.hasTemporaryChangeMarker"
            class="w-[0.1875rem] h-[0.1875rem]"
            :class="backgroundColorClass"
        />

        <div v-if="Boolean(props.selectedSpecialization) || props.hasTemporaryChangeMarker">
            <div
                class="size-4 flex items-center justify-center rounded-sm relative"
                :class="[backgroundColorClass, textColorClass]"
            >
                <div
                    v-if="props.hasTemporaryChangeMarker"
                    class="w-[0.375rem] h-[0.375rem] absolute bg-red-700 rounded-full right-[0.0625rem] top-[0.0625rem]"
                />

                <!-- Event icons inside -->
                <ClothesHangerIcon v-if="specializationIcon === 'ClothesHanger'" class="size-3" />
                <DoorBackIcon v-if="specializationIcon === 'DoorBack'" class="size-3" />
                <GroupIcon v-if="specializationIcon === 'Group'" class="size-3" />
                <InfoIcon v-if="specializationIcon === 'Info'" class="size-3" />
                <PackageIcon v-if="specializationIcon === 'Package'" class="size-3" />
                <ParkingIcon v-if="specializationIcon === 'Parking'" class="size-3" />
                <RestaurantIcon v-if="specializationIcon === 'Restaurant'" class="size-3" />
                <TruckIcon v-if="specializationIcon === 'Truck'" class="size-3" />
                <WalkingIcon v-if="specializationIcon === 'Walking'" class="size-3" />

                <!-- Event icons outside -->
                <CenterPointIcon v-if="specializationIcon === 'CenterPoint'" class="size-3" />
                <DirectionsRunIcon v-if="specializationIcon === 'DirectionsRun'" class="size-3" />
                <StoreFrontIcon v-if="specializationIcon === 'StoreFront'" class="size-3" />

                <!-- Project icons -->
                <EngineeringIcon v-if="specializationIcon === 'Engineering'" class="size-3" />
                <ConstructionIcon v-if="specializationIcon === 'Construction'" class="size-3" />
                <DangerousIcon v-if="specializationIcon === 'Dangerous'" class="size-3" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { autoUpdate, offset, useFloating } from '@floating-ui/vue';
import { useMachine } from '@xstate/vue';
import { computed, provide, ref, watchEffect } from 'vue';
import CenterPointIcon from '../../Icon/CenterPointIcon.vue';
import CloseIcon from '../../Icon/CloseIcon.vue';
import ClothesHangerIcon from '../../Icon/ClothesHangerIcon.vue';
import ConstructionIcon from '../../Icon/ConstructionIcon.vue';
import DangerousIcon from '../../Icon/DangerousIcon.vue';
import DeleteForeverIcon from '../../Icon/DeleteForeverIcon.vue';
import DirectionsRunIcon from '../../Icon/DirectionsRunIcon.vue';
import DoorBackIcon from '../../Icon/DoorBackIcon.vue';
import EngineeringIcon from '../../Icon/EngineeringIcon.vue';
import GroupIcon from '../../Icon/GroupIcon.vue';
import InfoIcon from '../../Icon/InfoIcon.vue';
import InsertLeftFilledIcon from '../../Icon/InsertLeftFilledIcon.vue';
import InsertLeftIcon from '../../Icon/InsertLeftIcon.vue';
import InsertLeftRemovedIcon from '../../Icon/InsertLeftRemovedIcon.vue';
import InsertRightFilledIcon from '../../Icon/InsertRightFilledIcon.vue';
import InsertRightIcon from '../../Icon/InsertRightIcon.vue';
import InsertRightRemovedIcon from '../../Icon/InsertRightRemovedIcon.vue';
import PackageIcon from '../../Icon/PackageIcon.vue';
import ParkingIcon from '../../Icon/ParkingIcon.vue';
import ReplaceIcon from '../../Icon/ReplaceIcon.vue';
import RestaurantIcon from '../../Icon/RestaurantIcon.vue';
import StoreFrontIcon from '../../Icon/StoreFrontIcon.vue';
import TruckIcon from '../../Icon/TruckIcon.vue';
import WalkingIcon from '../../Icon/WalkingIcon.vue';
import Tooltip from '../../Tooltip/Tooltip.vue';
import { triggerHideTooltipKey } from '../../Tooltip/injectionKeys';
import { PhaseType } from '../Timeline.types';
import { mapSpecializationsTypesToIcons, SpecializationType } from '../allocation/SpecializationMappings';
import {
    getActiveBackgroundColorClassForType,
    getBorderColorClassForType,
    getFillColorClassForType,
    getHoverStrongBackgroundColorClassForType,
    getHoverStrongTextColorClassForType,
    getLightBackgroundColorClassForType,
    getStrongBackgroundColorAfterClassForType,
    getStrongBackgroundColorClassForType,
    getTextColorClassForType,
} from '../allocation/shared';
import ChangeTypePopupContent from './ChangeTypePopupContent.vue';
import { createStateMachine } from './EditPhaseWidget.state';

type EditPhaseWidgetProps = {
    phaseType: PhaseType;
    direction: 'Left' | 'Both' | 'Right';
    isDecreasingDeleting: boolean;
    isDecreaseButtonDisabled: boolean;
    hasChangePhaseButton: boolean;
    cellSpanBefore: number;
    cellSpanAfter: number;
    isChangeTypePopupOpen: boolean;
    changePhaseStatus: 'Open-Popup' | 'Close-Popup' | 'Dont-Show';
    allowedSpecializations: (SpecializationType | null)[];
    selectedSpecialization: SpecializationType | null;
    hasTemporaryChangeMarker: boolean;
};

const props = defineProps<EditPhaseWidgetProps>();

const emit = defineEmits<{
    (e: 'decreasePhaseLength'): void;
    (e: 'increasePhaseLength', direction: 'Left' | 'Right'): void;
    (e: 'toggle-change-phase-type'): void;
    (e: 'change-specialization', specialization: SpecializationType | null): void;
}>();

const stateMachine = ref();
watchEffect(() => {
    stateMachine.value = createStateMachine({
        direction: props.direction,
        isDecreasingDeleting: props.isDecreasingDeleting,
        onDeletePhase: () => {
            triggerHideTooltip();
            emit('decreasePhaseLength');
        },
        onDecreasePhaseLength: () => {
            triggerHideTooltip();
            emit('decreasePhaseLength');
        },
        onIncreasePhaseLength: (direction) => {
            triggerHideTooltip();
            emit('increasePhaseLength', direction);
        },
        onReplacePhase: () => {
            triggerHideTooltip();
            emit('toggle-change-phase-type');
        },
    });
});
const { state, send } = useMachine(stateMachine.value);

const backgroundColorClass = computed(() => getStrongBackgroundColorClassForType(props.phaseType));
const textColorClass = computed(() => getTextColorClassForType(props.phaseType));

const buttonClasses = computed(() => {
    const lightBackgroundColor = getLightBackgroundColorClassForType(props.phaseType);
    const hoverStrongBackgroundColor = getHoverStrongBackgroundColorClassForType(props.phaseType);
    const hoverStrongTextColor = getHoverStrongTextColorClassForType(props.phaseType);
    const activeBackgroundColor = getActiveBackgroundColorClassForType(props.phaseType);

    const backgroundClasses = `${lightBackgroundColor} ${hoverStrongBackgroundColor} ${hoverStrongTextColor} ${activeBackgroundColor}`;

    if (props.phaseType === 'Durchführung') {
        return `text-gray-700 hover:text-gray-50 active:text-gray-50 ${backgroundClasses}`;
    }

    return `text-gray-700 active:text-gray-50 ${backgroundClasses}`;
});

const buttonDisabledClasses = computed(() => {
    return `
            bg-gray-100 text-gray-500 cursor-not-allowed
        `;
});

const deleteButtonClasses = computed(() => {
    return `
        bg-red-700 text-red-50
        hover:bg-red-800 hover:text-red-50
        active:bg-red-900 active:text-red-50
        focus-visible:outline-red-700
    `;
});

const iconColorClass = computed(() => {
    if (props.phaseType === 'Durchführung' || props.phaseType === 'Projekt') {
        return 'text-gray-50';
    }

    return 'text-gray-700';
});

// This is used to force close the Tooltip after clicking any button
const triggerHideTooltipKeyCounter = ref(0);
provide(triggerHideTooltipKey, triggerHideTooltipKeyCounter);

function triggerHideTooltip() {
    triggerHideTooltipKeyCounter.value = triggerHideTooltipKeyCounter.value + 1;
}

const borderColor = computed(() => getBorderColorClassForType(props.phaseType));
const backgroundColor = computed(() => getLightBackgroundColorClassForType(props.phaseType));
const afterBackgroundColor = computed(() => getStrongBackgroundColorAfterClassForType(props.phaseType));
const fillColor = computed(() => getFillColorClassForType(props.phaseType));

const changeTypeButtonRef = ref();
const changeTypePopupRef = ref();

const { floatingStyles } = useFloating(changeTypeButtonRef, changeTypePopupRef, {
    open: props.isChangeTypePopupOpen,
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [offset(20)],
});

const specializationIcon = computed(() => mapSpecializationsTypesToIcons(props.selectedSpecialization));

const isReplacePhaseTooltipHovered = ref(false);
</script>

<style scoped>
.connect-bar-inverted-border-radius {
    position: relative;
}

.connect-bar-inverted-border-radius::after {
    content: '';
    position: absolute;
    height: 4px;
    bottom: -5px;
    left: 50%;
    transform: translate(-50%);
}
</style>
