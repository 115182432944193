import { TimelineVariant } from '@/components/Timeline/allocation/types';
import { AllocationType } from '@/components/Timeline/Timeline.types';
import { DeleteEventAllocationRoomDocument, DeleteProjectAllocationRoomDocument } from '@/generated/graphql';
import { useMutation } from '@vue/apollo-composable';
import { Ref, toValue } from 'vue';

export function useDeleteAllocationRoom(
    variantsRef: Ref<TimelineVariant[]>,
    setVariantsRef: (value: TimelineVariant[]) => void,
    allocationType: AllocationType,
) {
    const mutations = {
        Project: useMutation(DeleteProjectAllocationRoomDocument),
        Event: useMutation(DeleteEventAllocationRoomDocument),
        None: undefined,
    } satisfies Record<AllocationType, unknown>;

    async function onDeleteAllocationRoom(allocationId: string, onDone: (isSuccess: boolean) => void) {
        const deleteMutation = mutations[allocationType];
        if (deleteMutation) {
            const result = await deleteMutation.mutate(
                { allocationId },
                {
                    update(cache, { data }) {
                        const allocation = data?.delete?.room;

                        if (!allocation) {
                            throw new Error(`Expected deleted allocation, got ${allocation}`);
                        }
                        cache.evict({
                            id: cache.identify(allocation),
                        });
                        cache.gc();
                    },
                },
            );
            if (result?.errors) {
                onDone(false);
            } else {
                const variantId = result?.data?.delete?.room?.variantId;
                const deletedAllocationId = result?.data?.delete?.room?.id;
                if (deletedAllocationId && variantId) {
                    const newValue = toValue(variantsRef).map((v) =>
                        v.id === variantId
                            ? {
                                  ...v,
                                  rooms: {
                                      nodes: v.rooms.nodes.filter((r) => r.id !== deletedAllocationId),
                                  },
                              }
                            : v,
                    );
                    setVariantsRef(newValue);
                }
                onDone(true);
            }
        }
    }
    return {
        onDeleteAllocationRoom,
    };
}
