<template>
    <div class="h-screen overflow-y-auto bg-gray-200">
        <header>
            <div
                v-if="slots.breadcrumb || slots.search"
                class="bg-gray-100 border-b border-b-gray-200 text-sm flex flex-row items-center justify-between px-4 h-12"
            >
                <slot name="breadcrumb" />

                <slot name="search" />
            </div>

            <div class="bg-gray-50 relative py-4 pr-4" :class="[props.variant === 'Logo' ? 'pl-36' : 'pl-4']">
                <div
                    v-if="props.variant === 'Logo'"
                    class="bg-gray-50 ring-1 ring-gray-200 w-28 h-28 rounded-lg absolute left-4 top-2 overflow-hidden flex items-center justify-center"
                    :class="{ 'border-4 border-red-700 bg-red-200': hasLoadingLogoFailed }"
                >
                    <p v-if="hasLoadingLogoFailed" class="text-center hyphens-auto p-1 max-w-full max-h-full">
                        {{ $t('loading-logo-failed') }}
                    </p>

                    <Tooltip :is-teleported="true" :is-template-element="true">
                        <template #trigger="tooltipProps">
                            <img
                                v-if="props.logoUrl && !hasLoadingLogoFailed"
                                :src="props.logoUrl"
                                :alt="props.logoAlt"
                                v-bind="tooltipProps"
                                @error="onImageError"
                            />

                            <p v-if="!props.logoUrl">{{ props.logoAlt }}</p>
                        </template>

                        <template #text>{{ $t('logo-edit-hint') }}</template>
                    </Tooltip>
                </div>
                <div class="flex flex-wrap gap-4 items-center justify-between">
                    <div class="flex gap-4 items-center font-semibold text-gray-700 text-5xl">
                        <slot name="icon" />
                        <h1 class="hyphens-auto">
                            {{ props.headline }}
                        </h1>
                        <slot name="headline-extra" />
                    </div>

                    <div class="flex flex-wrap gap-2">
                        <slot name="buttons" />
                    </div>
                </div>
            </div>

            <div class="bg-gray-100 border-t border-t-gray-200" :class="[props.variant === 'Logo' ? 'pl-36' : 'pl-4']">
                <slot name="tab-navigation" />
            </div>
        </header>

        <main class="p-4 w-full">
            <slot name="body" />
        </main>
    </div>
</template>

<script setup lang="ts">
import { ref, useSlots } from 'vue';
import Tooltip from '../Tooltip/Tooltip.vue';

type HeaderCardsLayoutProps = {
    logoUrl?: string;
    logoAlt?: string;
    headline: string;
    variant: 'Logo' | 'No-Logo';
};

const props = defineProps<HeaderCardsLayoutProps>();

const slots = useSlots();

defineEmits<{
    (e: 'enterEditMode'): void;
    (e: 'leaveEditMode'): void;
    (e: 'discardChanges'): void;
    (e: 'save'): void;
}>();

const hasLoadingLogoFailed = ref(false);

function onImageError(event: Event) {
    hasLoadingLogoFailed.value = true;

    console.error(event);
}
</script>
