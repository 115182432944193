<template>
    <Message v-if="errorDetails.error" :headline="$t('uncaptured-error')" status="error">
        <h1 class="text-2xl font-bold">{{ errorDetails.error }}</h1>
        <p class="mb-1 text-red-800 text-xs">{{ $t('view-error-details-in-console') }}</p>
        <pre class="bg-gray-50 p-4 text-gray-700 whitespace-pre-line">{{ errorDetails }}</pre>
    </Message>

    <router-view />
</template>

<script setup lang="ts">
import Message from '@/components/Message/Message.vue';
import * as Sentry from '@sentry/vue';
import { useHeadSafe } from '@unhead/vue';
import { onErrorCaptured, reactive } from 'vue';
import { getEnvironmentShort } from './utils/environment';

const errorDetails = reactive<{
    error: Error | null;
    componentName: string;
    info: string | null;
}>({
    error: null,
    componentName: '(Not defined)',
    info: null,
});

onErrorCaptured((error, instance, info) => {
    errorDetails.error = error;
    // The instance should not be outputted in the template as this leads to infinite loops in the production build
    errorDetails.componentName = instance?.$.type.__name ?? '(Unknown)';
    errorDetails.info = info;
    Sentry.captureException(error);
    console.error(error, instance, info);
});

//<Seite> | space.cap [<Umgebung>]
const envName = Object.create(globalThis.import_meta_env || null).ENV_NAME;
const environment = getEnvironmentShort(envName);

let titleTemplate = '%s %separator space.cap [%environment]';
if (envName === 'prod') {
    titleTemplate = '%s %separator space.cap';
}

useHeadSafe({
    templateParams: { environment, separator: '|' },
    titleTemplate,
});
</script>

<style>
body {
    margin: 0;
}
</style>
