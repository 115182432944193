import { AllocationPhaseInput, AllocationUsage, TimelineVariant } from '@/components/Timeline/allocation/types';
import { AllocationType } from '@/components/Timeline/Timeline.types';
import { CreateEventAllocationRoomDocument, CreateProjectAllocationRoomDocument } from '@/generated/graphql';
import { useMutation } from '@vue/apollo-composable';
import { DateTime } from 'luxon';
import { Ref, toValue } from 'vue';
import { getUsageIdFromName } from '../allocation';

export function useCreateAllocationRoom(
    variantsRef: Ref<TimelineVariant[]>,
    setVariantsRef: (value: TimelineVariant[]) => void,
    editableVariant: Ref<TimelineVariant | undefined>,
    usages: Ref<AllocationUsage[]>,
    allocationType: AllocationType,
) {
    const mutations = {
        Project: useMutation(CreateProjectAllocationRoomDocument),
        Event: useMutation(CreateEventAllocationRoomDocument),
        None: undefined,
    } satisfies Record<AllocationType, unknown>;

    async function onCreateAllocationRoom(
        locationId: string,
        startDateTime: DateTime,
        endDateTime: DateTime,
        allocationVariantId: string,
        phases: AllocationPhaseInput[],
        onDone: (isSuccess: boolean) => void,
    ) {
        phases = phases.map((p) => ({ ...p, usageId: getUsageIdFromName(toValue(usages), p.usageId, allocationType) }));
        try {
            const createMutation = mutations[allocationType];
            if (createMutation) {
                const result = await createMutation.mutate({
                    start: startDateTime.toISO(),
                    end: endDateTime.toISO(),
                    roomId: locationId,
                    variantId: allocationVariantId,
                    phases,
                });

                if (result?.data && editableVariant.value) {
                    const allocation = result.data.create?.room;
                    if (allocation) {
                        const newValue = variantsRef.value.map((v) =>
                            v.id === allocationVariantId
                                ? {
                                      ...v,
                                      rooms: {
                                          nodes: [...v.rooms.nodes, allocation],
                                      },
                                  }
                                : v,
                        );
                        setVariantsRef(newValue);
                    }
                }
                onDone(true);
            }
        } catch (error) {
            onDone(false);
            throw new Error('Could not create new allocation');
        }
    }

    return {
        onCreateAllocationRoom,
    };
}
