<!-- PHASE GROUP -->
<!-- TODO Accessibility: Text alternative for allocation parts? -->
<!-- TODO Allocation wrapper should not be focusable once the modal is open? -->
<!-- TODO Should pressing space key twice close the popup? -->
<!-- TODO Hover effect for allocation wrapper to indicate that mouse is correctly positioned over -->
<!-- TODO Focus effect for allocation wrapper to indicate that the component is focused -->
<!-- TODO Deleting + increasing main phase does not take into account the special functioning, increasing should reduce deleted phases maybe? -->

<!-- TAB NAVIGATION -->
<!-- TODO Active state tabs -->
<!-- TODO Tabs disabled -->

<!-- BUTTONS -->
<!-- TODO Focus-visible state for icons only buttons -->
<!-- TODO Button: Size should only determine padding, not height or line-height? -->
<!-- TODO Button focus color white as prop? -->

<!-- EDIT POPUP -->
<!-- TODO Does tab order make sense?? -->
<!-- TODO Display backdrop shadow if edit popup is open? -->
<!-- TODO Discard all changes when closing the popup? -->
<!-- TODO Closing and reopening the edit popup should reset the selected tab position -->
<!-- TODO Implement change date + change time tabs correctly -->
<!-- TODO The edit popup can move out of the screen without a scrollbar if too many phases are added -->

<!-- ALLOCATION -->
<!-- TODO Change allocation wrapper border when edit popup is open -->
<!-- TODO Display name of external event in allocation bar -->
<!-- TODO Rewrite algorithm to calculate allocation layout (includes duplicated properties that are no longer required) -->
<!-- TODO Main phases have some edge cases when inserting cells from one side and deleting from the other one that lead to thrown errors -->
<!-- TODO Deleting more phases than existing is not prevented ==> Which text is shown should depend on the XState state, not on the passed props -->
<!-- TODO Deleting the main phase should not be possible -->

<template>
    <div
        class="relative inline-flex flex-col h-3 mr-24"
        :class="{
            'z-50': isEditPopupOpen,
        }"
        @keyup="onKeyUp"
    >
        <Teleport to="body">
            <EditPopup
                v-if="isEditPopupOpen"
                ref="editPopupRef"
                :style="{ ...floatingStyles, ...widthStyle }"
                :left-compensation-in-rem="leftRightCompensation.left"
                :right-compensation-in-rem="leftRightCompensation.right"
            >
                <template v-if="isReviewSectionVisible" #review-section>
                    <ReviewSection :variant="isDeleteConfirmationVisible ? 'Error' : 'Neutral'">
                        <div class="flex flex-col gap-1">
                            <template v-if="isDeleteConfirmationVisible">
                                <div class="flex flex-row gap-1 justify-between">
                                    <Button
                                        size="Extra-Small"
                                        variant="Neutral-Light"
                                        @click="send('Abort deleting allocation')"
                                    >
                                        <template #icon>
                                            <UndoIcon />
                                        </template>
                                        {{ $t('keep-allocation') }}
                                    </Button>

                                    <Button
                                        v-if="
                                            state.matches(
                                                'Edit popup open.Tab navigation.Edit phases.Delete area.Delete area open.Default',
                                            )
                                        "
                                        size="Extra-Small"
                                        variant="Danger-Light"
                                        @click="send('Confirm deleting allocation')"
                                    >
                                        <template #icon>
                                            <DeleteForeverIcon />
                                        </template>
                                        {{ $t('delete-allocation-permanently') }}
                                    </Button>

                                    <Button
                                        v-if="
                                            state.matches(
                                                'Edit popup open.Tab navigation.Edit phases.Delete area.Delete area open.Deleting allocation pending',
                                            )
                                        "
                                        size="Extra-Small"
                                        variant="Danger-Light"
                                        :disabled-explanation="$t('deleting-allocation-pending')"
                                    >
                                        <template #icon>
                                            <SaveIcon />
                                        </template>
                                        {{ $t('deleting-allocation-pending-label') }}
                                    </Button>

                                    <Button
                                        v-if="
                                            state.matches(
                                                'Edit popup open.Tab navigation.Edit phases.Delete area.Delete area open.Retry deleting allocation',
                                            )
                                        "
                                        size="Extra-Small"
                                        variant="Danger-Light"
                                        @click="send('Confirm phase changes')"
                                    >
                                        <template #icon>
                                            <RefreshIcon />
                                        </template>
                                        {{ $t('deleting-allocation-retry') }}
                                    </Button>
                                </div>
                            </template>

                            <template v-if="isPhaseChangeConfirmationVisible">
                                <div class="flex flex-row gap-1 justify-between">
                                    <Button
                                        size="Extra-Small"
                                        variant="Neutral-Light"
                                        @click="send('Discard phase changes')"
                                    >
                                        <template #icon>
                                            <UndoIcon />
                                        </template>
                                        {{ $t('discard-changes') }}
                                    </Button>

                                    <Button
                                        v-if="
                                            state.matches(
                                                'Edit popup open.Tab navigation.Edit phases.Review area.Review area open.Default',
                                            )
                                        "
                                        size="Extra-Small"
                                        variant="Info-Light"
                                        @click="send('Confirm phase changes')"
                                    >
                                        <template #icon>
                                            <SaveIcon />
                                        </template>
                                        {{ $t('change-phases-permanently') }}
                                    </Button>

                                    <Button
                                        v-if="
                                            state.matches(
                                                'Edit popup open.Tab navigation.Edit phases.Review area.Review area open.Phase update pending',
                                            )
                                        "
                                        size="Extra-Small"
                                        variant="Info-Light"
                                        :disabled-explanation="$t('phase-update-pending')"
                                    >
                                        <template #icon>
                                            <SaveIcon />
                                        </template>
                                        {{ $t('phase-update-pending-label') }}
                                    </Button>

                                    <Button
                                        v-if="
                                            state.matches(
                                                'Edit popup open.Tab navigation.Edit phases.Review area.Review area open.Retry phase changes update',
                                            )
                                        "
                                        size="Extra-Small"
                                        variant="Danger-Light"
                                        @click="send('Confirm phase changes')"
                                    >
                                        <template #icon>
                                            <RefreshIcon />
                                        </template>
                                        {{ $t('phase-update-retry') }}
                                    </Button>
                                </div>
                            </template>
                        </div>
                    </ReviewSection>
                </template>

                <template #navigation-section>
                    <div class="flex flex-row w-full" :style="widthStyle">
                        <div class="order-2 flex flex-col flex-grow items-center justify-center">
                            <h2 class="text-xl font-bold text-gray-100 w-max mx-2 flex">
                                <RouterLink
                                    :to="props.linkTarget"
                                    class="focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-gray-50 px-2 rounded hover:bg-gray-700 hover:underline"
                                    target="_blank"
                                >
                                    {{ props.allocationLabel }}
                                </RouterLink>
                            </h2>
                        </div>

                        <div class="order-3 flex flex-row gap-1.5 items-center px-1.5">
                            <div class="w-[2.0625rem]" />

                            <Button
                                size="Extra-Small"
                                variant="Danger-Strong"
                                :icon-description="$t('delete-allocation')"
                                @click="
                                    triggerHideTooltip();
                                    send('Delete allocation');
                                "
                            >
                                <template #icon>
                                    <DeleteForeverIcon class="size-4" />
                                </template>
                            </Button>

                            <div class="bg-gray-500 w-px h-6" />

                            <Button
                                class="text-gray-200"
                                size="Extra-Small"
                                variant="Transparent"
                                :icon-description="$t('close-allocation-edit-popup')"
                                @click="send('Close popup')"
                            >
                                <template #icon>
                                    <CloseIcon class="size-4" />
                                </template>
                            </Button>
                        </div>

                        <div class="order-1 self-end">
                            <div class="flex flex-row gap-1 mx-2.5">
                                <AllocationEditTabNavigationItem
                                    icon="Edit-Phases"
                                    :status="isEditPhasesTabActive ? 'Active' : 'Default'"
                                    @change-tab="
                                        triggerHideTooltip();
                                        send('Click tab edit phases');
                                    "
                                />

                                <AllocationEditTabNavigationItem
                                    icon="Move-Date"
                                    :status="isMoveDateTabActive ? 'Active' : 'Default'"
                                    @change-tab="
                                        triggerHideTooltip();
                                        send('Click tab move date');
                                    "
                                />

                                <AllocationEditTabNavigationItem
                                    icon="Change-Time"
                                    :status="isChangeTimeTabActive ? 'Active' : 'Default'"
                                    @change-tab="
                                        triggerHideTooltip();
                                        send('Click tab change time');
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </template>

                <template #content-section>
                    <div
                        v-if="isEditPhasesTabActive"
                        class="bg-gray-50 wrapper-border-gray-700 rounded-lg flex flex-row gap-[0.0625rem]"
                    >
                        <PhaseGroup
                            v-for="(phaseGroup, phaseGroupIndex) in state.context.phaseGroups"
                            :key="phaseGroup.id"
                            :cell-span-before="phaseGroup.temporaryCellSpanBefore"
                            :confirmed-cell-span="phaseGroup.confirmedCellSpan"
                            :cell-span-after="phaseGroup.temporaryCellSpanAfter"
                            :is-left-end-position="phaseGroup.isLeftEndPosition"
                            :is-right-end-position="phaseGroup.isRightEndPosition"
                            :position="phaseGroup.position"
                            :insert-phase-before="phaseGroup.insertPhaseBefore"
                            :insert-phase-after="phaseGroup.insertPhaseAfter"
                            :type="phaseGroup.type"
                            :allowed-specializations="
                                getAllowedSpecializationTypesForPhaseType(phaseGroup.type, props.isInside)
                            "
                            :selected-specialization="
                                phaseGroup.temporarySpecialization ?? phaseGroup.confirmedSpecialization ?? null
                            "
                            :has-temporary-change-marker="
                                Boolean(phaseGroup.temporarySpecialization) &&
                                phaseGroup.temporarySpecialization !== phaseGroup.confirmedSpecialization
                            "
                            :is-change-type-popup-open="phaseGroup.isChangeTypePopupOpen"
                            @insert-phase-before="
                                send({
                                    type: 'Insert phase',
                                    position:
                                        phaseGroup.position === 'Before-Main' || phaseGroup.position === 'Main'
                                            ? 'Before'
                                            : 'After',
                                    insertingPosition:
                                        phaseGroup.position === 'Before-Main' || phaseGroup.position === 'Main'
                                            ? phaseGroupIndex
                                            : phaseGroupIndex - 1,
                                }),
                                    triggerHideTooltip()
                            "
                            @insert-phase-after="
                                send({
                                    type: 'Insert phase',
                                    position: 'After',
                                    insertingPosition: phaseGroupIndex,
                                }),
                                    triggerHideTooltip()
                            "
                            @increase-phase-length-before="
                                send({
                                    type: 'Increase phase length',
                                    position: 'Before',
                                    phaseGroupType: phaseGroup.type,
                                })
                            "
                            @decrease-phase-length-before="
                                send({
                                    type: 'Decrease phase length',
                                    position: 'Before',
                                    phaseGroupType: phaseGroup.type,
                                })
                            "
                            @increase-phase-length-after="
                                send({
                                    type: 'Increase phase length',
                                    position: 'After',
                                    phaseGroupType: phaseGroup.type,
                                })
                            "
                            @decrease-phase-length-after="
                                send({
                                    type: 'Decrease phase length',
                                    position: 'After',
                                    phaseGroupType: phaseGroup.type,
                                })
                            "
                            @show-change-type-popup="
                                send({
                                    type: 'Show change type popup',
                                    phaseGroupType: phaseGroup.type,
                                })
                            "
                            @hide-change-type-popup="
                                send({
                                    type: 'Hide change type popup',
                                })
                            "
                            @change-specialization="
                                (specialization) =>
                                    send({
                                        type: 'Change specialization type',
                                        specialization,
                                        phaseGroupId: phaseGroup.id,
                                    })
                            "
                        />
                    </div>

                    <template v-if="isMoveDateTabActive">
                        <TextField class="absolute -bottom-8 left-0" label="Start" value="" />

                        <TextField class="absolute -bottom-8 right-0" label="Ende" value="" />
                    </template>

                    <template v-if="isChangeTimeTabActive">
                        <TextField class="absolute -bottom-8 left-0" label="Start-Zeit" value="" />

                        <TextField class="absolute -bottom-8 right-0" label="End-Zeit" value="" />
                    </template>
                </template>
            </EditPopup>
        </Teleport>

        <!-- Allocation -->
        <div class="flex">
            <AllocationWrapper
                ref="allocationWrapperRef"
                :status="state.matches('Activated') ? 'Focused' : !isEditPopupOpen ? 'Default' : 'Open'"
                :is-editable="props.isEditable"
                :allocation-variant-label="props.allocationVariantLabel"
                :style="allocationWrapperWidthStyle"
                @mousedown="send('Activate popup')"
                @mouseup="
                    triggerHideTooltip();
                    send('Open popup');
                "
                @focus="send('Activate popup')"
                @blur="send('Blur')"
                @keypress.esc="send('Close popup')"
                @keypress.space="send('Open popup')"
            >
                <template v-for="(phaseGroup, phaseGroupIndex) in state.context.phaseGroups" v-if="!isEditPopupOpen">
                    <AllocationPart
                        v-for="cellIndex in phaseGroup.confirmedCellSpan"
                        :key="`${phaseGroup.id}-${cellIndex}`"
                        :type="phaseGroup.type"
                        :form="
                            getAllocationPartForm(
                                phaseGroupIndex,
                                state.context.phaseGroups.length,
                                phaseGroup.confirmedCellSpan,
                                cellIndex - 1,
                            )
                        "
                        :is-phase-end="cellIndex === phaseGroup.confirmedCellSpan"
                        variant="Solid"
                        :opacity-variant="props.variant"
                        :specialization="
                            phaseGroup.temporarySpecialization ?? phaseGroup.confirmedSpecialization ?? null
                        "
                        :has-temporary-change-marker="
                            Boolean(phaseGroup.temporarySpecialization) &&
                            phaseGroup.temporarySpecialization !== phaseGroup.confirmedSpecialization
                        "
                    />
                </template>
            </AllocationWrapper>
        </div>
    </div>
</template>

<script setup lang="ts">
import { autoUpdate, useFloating } from '@floating-ui/vue';
import { useElementSize } from '@vueuse/core';
import { useMachine } from '@xstate/vue';
import { DateTime } from 'luxon';
import { computed, inject, provide, ref, watchEffect } from 'vue';
import { RouteLocationRaw, RouterLink } from 'vue-router';
import Button from '../../Button/Button.vue';
import TextField from '../../Form/TextField.vue';
import CloseIcon from '../../Icon/CloseIcon.vue';
import DeleteForeverIcon from '../../Icon/DeleteForeverIcon.vue';
import RefreshIcon from '../../Icon/RefreshIcon.vue';
import SaveIcon from '../../Icon/SaveIcon.vue';
import UndoIcon from '../../Icon/UndoIcon.vue';
import { triggerHideTooltipKey } from '../../Tooltip/injectionKeys';
import { useTimelineStore } from '../Timeline.store';
import { AllocationPhase, AllocationPhaseUpdate, AllocationType, PhaseForm, PhaseType } from '../Timeline.types';
import AllocationEditTabNavigationItem from '../edit-popup/AllocationEditTabNavigationItem.vue';
import EditPopup from '../edit-popup/EditPopup.vue';
import ReviewSection from '../edit-popup/ReviewSection.vue';
import { emitDeleteAllocationKey, emitUpdatePhasesKey } from '../injectionKeys';
import PhaseGroup from '../phase-group/PhaseGroup.vue';
import { columnWidthInRem } from '../sharedStyles';
import { createStateMachine } from './Allocation.state';
import AllocationPart from './AllocationPart.vue';
import AllocationWrapper from './AllocationWrapper.vue';
import { SpecializationType } from './SpecializationMappings';

type AllocationProps = {
    allocationId: string;
    allocationType: AllocationType;
    allocationLabel: string;
    allocationVariantId: string;
    allocationVariantLabel?: string;
    phases: AllocationPhase[];
    startDateTime: DateTime;
    endDateTime: DateTime;
    isEditable: boolean;
    linkTarget: RouteLocationRaw;
    variant: 'Solid' | 'Outer-Semi-Transparent' | 'Semi-Transparent';
    locationId: string;
    isInside: boolean;
};

const props = defineProps<AllocationProps>();

defineEmits<{
    (e: 'close-allocation'): void;
}>();

// TODO Can we move this up higher? Otherwise a child element of the allocation needs to have the focus
function onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
        case 'Delete':
            send('Delete allocation');
            break;

        case 'Escape':
            send('Close popup');
            break;
    }
}

const updatePhases = inject(emitUpdatePhasesKey);

function onDeleteAllocationDone(isSuccess: boolean) {
    if (isSuccess) {
        send('Delete allocation');
    } else {
        send('Deleting allocation failed');
    }
}

async function onDeleteAllocation() {
    if (!deleteAllocation) {
        throw new Error('Missing injected deleteAllocation function');
    }

    deleteAllocation(props.allocationId, onDeleteAllocationDone);
}

const deleteAllocation = inject(emitDeleteAllocationKey);

async function onUpdatePhases(phases: AllocationPhaseUpdate[]): Promise<AllocationPhaseUpdate[]> {
    if (!updatePhases) {
        throw new Error('Missing injected updatePhases function');
    }

    return new Promise((resolve, reject) => {
        updatePhases(
            phases,
            props.startDateTime,
            props.endDateTime,
            newLocationIds.value,
            props.allocationId,
            props.allocationType,
            props.allocationVariantId,
            (isSuccess, phaseUpdates: AllocationPhaseUpdate[]) => {
                if (isSuccess) {
                    resolve(phaseUpdates);
                } else {
                    reject();
                }
            },
        );
    });
}

const store = useTimelineStore();

const stateMachine = computed(() => {
    return createStateMachine(
        props.phases,
        props.isEditable,
        onDeleteAllocation,
        onUpdatePhases,
        props.allocationType,
        (isOpen: boolean) => {
            if (isOpen) {
                store.showCheckboxes();
                store.toggleLocationCheckbox(props.locationId, 'Disabled-Checked');
            } else {
                store.hideCheckboxes();
            }
        },
        () => {
            if (!store._initialLocationId) {
                throw new Error('Missing initial location ID when discarding changes');
            }
            const initialLocationId = store._initialLocationId; // Store temporary copy as this will be reset when hiding all checkboxes

            // TODO There is room for performance optimization here
            store.hideCheckboxes();
            store.showCheckboxes();
            store.toggleLocationCheckbox(initialLocationId, 'Disabled-Checked');
        },
    );
});
const { state, send } = useMachine(stateMachine.value);

const newLocationIds = computed(() => {
    return store.checkedLocationIds.filter((locationId) => locationId !== props.locationId);
});

watchEffect(() => {
    send({ type: 'Location selection changed', countOtherLocations: newLocationIds.value.length });
});

const isEditPopupOpen = computed(() => state.value.matches('Edit popup open'));
const isDeleteConfirmationVisible = computed(() =>
    state.value.matches('Edit popup open.Tab navigation.Edit phases.Delete area.Delete area open'),
);
const isPhaseChangeConfirmationVisible = computed(() =>
    state.value.matches('Edit popup open.Tab navigation.Edit phases.Review area.Review area open'),
);
const isReviewSectionVisible = computed(
    () => isDeleteConfirmationVisible.value || isPhaseChangeConfirmationVisible.value,
);
const isEditPhasesTabActive = computed(() => state.value.matches('Edit popup open.Tab navigation.Edit phases'));
const isMoveDateTabActive = computed(() => state.value.matches('Edit popup open.Tab navigation.Move date'));
const isChangeTimeTabActive = computed(() => state.value.matches('Edit popup open.Tab navigation.Change time'));

const editPopupRef = ref(null);
const { width: editPopupWidth } = useElementSize(editPopupRef);

const minimalRequiredWidth = ref(0);
watchEffect(() => {
    if (minimalRequiredWidth.value === 0 && editPopupWidth.value !== 0) {
        minimalRequiredWidth.value = editPopupWidth.value;
    }
});

const allocationWrapperRef = ref<InstanceType<typeof AllocationWrapper>>();

const increasedWidthLeftCompensationPhaseCount = computed(() => {
    return state.value.context.phaseGroups.reduce((sum, phaseGroup) => {
        if (phaseGroup.temporaryCellSpanAfter > 0) {
            return sum + phaseGroup.temporaryCellSpanAfter;
        }

        return sum;
    }, 0);
});

const increasedWidthRightCompensationPhaseCount = computed(() => {
    return state.value.context.phaseGroups.reduce((sum, phaseGroup) => {
        if (phaseGroup.temporaryCellSpanBefore > 0) {
            return sum + phaseGroup.temporaryCellSpanBefore;
        }

        return sum;
    }, 0);
});

const leftRightCompensation = computed(() => {
    const leftCount = Math.max(
        increasedWidthLeftCompensationPhaseCount.value - increasedWidthRightCompensationPhaseCount.value,
        0,
    );
    const rightCount = Math.max(
        increasedWidthRightCompensationPhaseCount.value - increasedWidthLeftCompensationPhaseCount.value,
        0,
    );

    return {
        left: (columnWidthInRem + 0.0625) * leftCount,
        right: (columnWidthInRem + 0.0625) * rightCount,
    };
});

const widthStyle = computed(() => {
    return {
        minWidth: `${minimalRequiredWidth.value}px`,
    };
});

// Required to calculate the width of the allocation bar when the edit popup is open as the phase groups are placed on a separate allocation bar inside the edit popup and thus the reference width would be 0 otherwise and the positioning of the edit popup would fail
const allocationWrapperWidthStyle = computed(() => {
    const cellCount = state.value.context.phaseGroups.reduce((count, phaseGroup) => {
        return count + phaseGroup.confirmedCellSpan;
    }, 0);

    return {
        minWidth: `calc(${columnWidthInRem * cellCount}rem + ${cellCount - 1}px)`,
    };
});

const { floatingStyles } = useFloating(allocationWrapperRef, editPopupRef, {
    open: isEditPopupOpen,
    placement: 'top',
    whileElementsMounted: autoUpdate,
});

function getAllocationPartForm(
    phaseGroupIndex: number,
    phaseGroupsCount: number,
    cellsInPhaseGroup: number,
    cellIndex: number,
): PhaseForm {
    if (phaseGroupsCount === 1 && cellsInPhaseGroup === 1) {
        return 'Single' as const;
    }

    if (phaseGroupIndex === 0 && cellIndex === 0) {
        return 'Start' as const;
    }

    if (phaseGroupIndex === phaseGroupsCount - 1 && cellIndex === cellsInPhaseGroup - 1) {
        return 'End' as const;
    }

    return 'Middle' as const;
}

// This is used to force close the Tooltip after clicking the button
const triggerHideTooltipKeyCounter = ref(0);
provide(triggerHideTooltipKey, triggerHideTooltipKeyCounter);

function triggerHideTooltip() {
    triggerHideTooltipKeyCounter.value = triggerHideTooltipKeyCounter.value + 1;
}

function getAllowedSpecializationTypesForPhaseType(
    phaseType: PhaseType,
    isInside: boolean,
): (SpecializationType | null)[] {
    if (phaseType === 'Durchführung') {
        if (isInside) {
            return [
                null,
                'Parking',
                'Catering',
                'Storage',
                'Mobile-Entrance',
                'Congress',
                'Logistics',
                'Cloakroom',
                'Service-Center',
                'Routing',
            ];
        } else {
            return [null, 'Service-Assembly-Point', 'Open-Air-Ground', 'Evacuation-Corridor'];
        }
    }

    if (phaseType === 'Projekt') {
        return [null, 'Building-Activity', 'Maintenance', 'Forbidden-Zone'];
    }

    return [];
}
</script>

<style scoped>
.wrapper-border-gray-700::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 8px;
    border: 1px solid theme('colors.gray.700');
}
</style>
