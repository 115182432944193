import { FormMultilink, Multilink } from '@/components/MultiLink/types';

export type ProjectListItem = {
    id: string;
    nameShort: string;
    status: string;
    nameLong?: string;
};

export type ProjectSeriesListItem = {
    id: string;
    nameShort: string;
    projects?: {
        nodes: ProjectListItem[];
    };
};

export const projectSeriesStates = ['active', 'discontinued', 'completed'] as const;
export type ProjectSeriesStatus = (typeof projectSeriesStates)[number];

export const projectStates = ['active-idea', 'active-option', 'active-fix', 'discontinued', 'completed'] as const;
export type ProjectStatus = (typeof projectStates)[number];

export const projectVariantStates = ['active-1', 'active-2', 'passive', 'discontinued'] as const;
export type ProjectVariantStatus = (typeof projectVariantStates)[number];

export type Project = {
    id: string;
    nameShort: string;
    nameLong: string;
    subtitle: string;
    executionYear?: number;
    description: string;
    category: string;
    type: string;
    disturbanceNoise: string;
    disturbanceDust: string;
    disturbanceOther: string;
    companyGroup: string;
    businessDomain: string;
    department: string;
    organisationalChanges: string;
    notesDayOrder: string;
    notesAppointment: string;
    locationRequirements: string;
    allocationHints: string;
    otherDocuments?: Multilink;
    otherSystems?: Multilink;
    otherLinks?: Multilink;
    status: ProjectStatus;
    series: {
        id: string;
        nameShort: string;
    };
};

export type ProjectMultilinks = {
    [key: string]: FormMultilink;
    otherDocuments: FormMultilink;
    otherSystems: FormMultilink;
    otherLinks: FormMultilink;
};

export type ProjectSeries = {
    id: string;
    nameShort: string;
    nameLong: string;
    notes: string;
    description: string;
    websites?: Multilink;
    category: string;
    type: string;
    companyGroup: string;
    businessDomain: string;
    department: string;
    organisationalChanges: string;
    cycle: string;
    notesCycle: string;
    notesDayOrder: string;
    notesAppointment: string;
    locationRequirements: string;
    allocationHints: string;
    otherDocuments?: Multilink;
    otherSystems?: Multilink;
    otherLinks?: Multilink;
    singleProject: boolean;
    projects?: {
        nodes: ProjectListItem[];
    };
};

export type ProjectSeriesMultilinks = {
    [key: string]: FormMultilink;
    websites: FormMultilink;
    otherDocuments: FormMultilink;
    otherSystems: FormMultilink;
    otherLinks: FormMultilink;
};

export type UpdateProjectSeriesMultilinkParams = {
    [key: string]: string | undefined;
    id: string;
    websitesId?: string;
    otherDocumentsId?: string;
    otherSystemsId?: string;
    otherLinksId?: string;
};

export type ProjectSeriesErrors = {
    nameShort: string[];
    nameLong: string[];
    notes: string[];
    description: string[];
    websites?: string[];
    category: string[];
    type: string[];
    companyGroup: string[];
    businessDomain: string[];
    department: string[];
    organisationalChanges: string[];
    cycle: string[];
    notesCycle: string[];
    notesDayOrder: string[];
    notesAppointment: string[];
    locationRequirements: string[];
    allocationHints: string[];
    otherDocuments?: string[];
    otherSystems?: string[];
    otherLinks?: string[];
    singleProject: string[];
};

export type ProjectSeriesCreate = {
    [key: string]: boolean | string;
    singleProject: boolean;
    nameShort: string;
    nameLong: string;
};

export type ProjectCreate = {
    [key: string]: string;
    nameShort: string;
    nameLong: string;
    status: ProjectStatus;
};

export type ProjectErrors = {
    nameShort: string[];
    nameLong: string[];
    subtitle: string[];
    executionYear: string[];
    description: string[];
    category: string[];
    type: string[];
    companyGroup: string[];
    businessDomain: string[];
    department: string[];
    organisationalChanges: string[];
    disturbanceNoise: string[];
    disturbanceDust: string[];
    disturbanceOther: string[];
    notesDayOrder: string[];
    notesAppointment: string[];
    locationRequirements: string[];
    allocationHints: string[];
    otherDocuments?: string[];
    otherSystems?: string[];
    otherLinks?: string[];
};
