import { DateTime } from 'luxon';

function sortByDateAsc(a: string, b: string) {
    const startA = DateTime.fromISO(a);
    const startB = DateTime.fromISO(b);
    return startA < startB ? -1 : startA > startB ? 1 : 0;
}

function sortByDateDesc(a: string, b: string) {
    const startA = DateTime.fromISO(a);
    const startB = DateTime.fromISO(b);
    return startA > startB ? -1 : startA < startB ? 1 : 0;
}

export function calculateTimelineStart(
    executionYear: number,
    allVariants: {
        rooms: {
            nodes: {
                start: string;
                end: string;
            }[];
        };
    }[],
) {
    const allocationsMin = [];
    for (const variant of allVariants) {
        const allocations = variant.rooms.nodes;
        if (allocations.length > 0) {
            allocationsMin.push(allocations.toSorted((a, b) => sortByDateAsc(a.start, b.start))[0].start);
        }
    }
    let variantsStartMin = undefined;
    if (allocationsMin.length > 0) {
        variantsStartMin = DateTime.fromISO(allocationsMin.toSorted((a, b) => sortByDateAsc(a, b))[0]);
    }
    return variantsStartMin ? variantsStartMin.minus({ days: 2 }) : DateTime.fromISO(executionYear.toString());
}

export function calculateTimelineEnd(
    executionYear: number,
    allVariants: {
        rooms: {
            nodes: {
                start: string;
                end: string;
            }[];
        };
    }[],
) {
    const allocationsMax = [];
    for (const variant of allVariants) {
        const allocations = variant.rooms.nodes;
        if (allocations.length > 0) {
            allocationsMax.push(allocations.toSorted((a, b) => sortByDateDesc(a.end, b.end))[0].end);
        }
    }
    let variantsEndMax = undefined;
    if (allocationsMax.length > 0) {
        variantsEndMax = DateTime.fromISO(allocationsMax.toSorted((a, b) => sortByDateDesc(a, b))[0]);
    }
    return variantsEndMax
        ? variantsEndMax.plus({ days: 1 })
        : DateTime.fromISO(executionYear.toString()).plus({ months: 2 }).endOf('month').startOf('day');
}
