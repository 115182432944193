<template>
    <section
        class="flex flex-row gap-2"
        :class="{
            'bg-emerald-100 -mx-1.5 px-1.5 -mt-1.5 pt-1.5 rounded': props.hasUnsavedChanges === 'New',
            'bg-sky-100 -mx-1.5 px-1.5 -mt-1.5 pt-1.5 rounded': props.hasUnsavedChanges === 'Edited',
            'bg-red-100 -mx-1.5 px-1.5 -mt-1.5 pt-1.5 rounded': props.hasUnsavedChanges === 'Deleted',
        }"
    >
        <div class="flex flex-col items-center">
            <div class="w-8 h-8 shrink-0 rounded-full relative">
                <Tooltip :is-disabled="!hasLoadingLogoFailed">
                    <template #trigger="tooltipProps">
                        <img
                            alt=""
                            class="object-cover w-8 h-8 rounded-full focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-red-700"
                            :class="{
                                'border-4 border-red-700 bg-red-200': hasLoadingLogoFailed,
                                grayscale: props.hasUnsavedChanges === 'Deleted',
                            }"
                            :src="props.imageUrl"
                            :tabindex="hasLoadingLogoFailed ? 0 : -1"
                            :aria-labelledby="hasLoadingLogoFailed ? tooltipProps['aria-labelledby'] : headlineId"
                            @error="onImageError"
                        />
                    </template>

                    <template #text>
                        <div class="w-max">{{ $t('loading-avatar-failed', { name: props.name }) }}</div>
                    </template>
                </Tooltip>

                <div
                    class="bg-gray-50 text-gray-600 flex place-items-center place-content-center rounded-tl w-3.5 h-3.5 absolute bottom-0 right-0"
                >
                    <CalendarEditIcon class="w-3 h-3" />
                </div>
            </div>

            <div
                class="border-l border-l-solid h-full w-px mt-2 translate-x-[0.5px]"
                :class="{
                    'mb-2': props.hasUnsavedChanges !== 'None',
                    'border-l-gray-300': props.hasUnsavedChanges === 'None',
                    'border-l-emerald-200': props.hasUnsavedChanges === 'New',
                    'border-l-sky-200': props.hasUnsavedChanges === 'Edited',
                    'border-l-red-200': props.hasUnsavedChanges === 'Deleted',
                }"
            />
        </div>

        <div class="flex flex-col w-full pb-2">
            <div class="flex flex-row justify-between items-start">
                <div class="flex flex-col">
                    <h3 :id="headlineId" class="font-medium text-gray-700 flex flex-row gap-1 items-center">
                        <span :class="{ 'line-through': props.hasUnsavedChanges === 'Deleted' }">
                            {{ props.name }}
                        </span>

                        <span v-if="props.hasUnsavedChanges === 'New'" class="font-light text-emerald-600 text-sm">
                            {{ $t('temporarily-new-comment') }}
                        </span>
                        <span v-if="props.hasUnsavedChanges === 'Edited'" class="font-light text-sky-600 text-sm">
                            {{ $t('temporarily-edited-comment') }}
                        </span>
                        <span v-if="props.hasUnsavedChanges === 'Deleted'" class="font-light text-red-600 text-sm">
                            {{ $t('temporarily-marked-for-deletion-comment') }}
                        </span>
                    </h3>
                    <time
                        :datetime="props.dateTime.toISODate()"
                        class="text-xs font-light text-gray-500"
                        :class="{ 'line-through': props.hasUnsavedChanges === 'Deleted' }"
                    >
                        {{ props.dateTime.toLocaleString() }}
                    </time>
                </div>

                <div v-if="props.isEditable" class="flex flex-row gap-1.5">
                    <Button
                        v-if="!isInEditMode"
                        ref="editButtonRef"
                        size="Small"
                        variant="Info-Strong"
                        :icon-description="$t('edit-comment')"
                        :disabled-explanation="
                            props.hasUnsavedChanges === 'Deleted' ? $t('marked-for-deletion') : undefined
                        "
                        @click="isInEditMode = true"
                    >
                        <template #icon>
                            <EditIcon class="w-4 h-4" />
                        </template>
                    </Button>

                    <Button
                        v-if="isInEditMode"
                        ref="abortButtonRef"
                        size="Small"
                        :variant="hasChanges ? 'Danger-Light' : 'Info-Light'"
                        @click="(commentValue = ''), (isInEditMode = false)"
                    >
                        <template #icon>
                            <UndoIcon />
                        </template>

                        <template v-if="!hasChanges">{{ $t('stop-editing-comment') }}</template>
                        <template v-if="hasChanges">{{ $t('discard-comment-changes') }}</template>
                    </Button>

                    <Button
                        v-if="!isInEditMode && props.hasUnsavedChanges !== 'Deleted'"
                        size="Small"
                        variant="Danger-Strong"
                        :icon-description="$t('delete-comment')"
                        @click="emit('delete-comment')"
                    >
                        <template #icon>
                            <DeleteForeverIcon class="w-4 h-4" />
                        </template>
                    </Button>

                    <Button
                        v-if="!isInEditMode && props.hasUnsavedChanges === 'Deleted'"
                        size="Small"
                        variant="Info-Strong"
                        :icon-description="$t('undo-marking-for-deletion')"
                        @click="emit('undo-deleting-comment')"
                    >
                        <template #icon>
                            <UndoIcon class="w-4 h-4" />
                        </template>
                    </Button>
                </div>
            </div>

            <p
                v-if="!isInEditMode"
                class="text-gray-600 text-sm font-light whitespace-break-spaces"
                :class="{ 'line-through': props.hasUnsavedChanges === 'Deleted' }"
            >
                {{ props.text }}
            </p>

            <div v-if="isInEditMode" class="flex flex-col gap-2 mt-2">
                <TextAreaField :label="$t('label-comment')" :value="props.text" @input="onCommentChange" />

                <div class="place-self-end">
                    <Button
                        size="Small"
                        variant="Success-Strong"
                        @click="emit('update-comment', commentValue), (isInEditMode = false)"
                    >
                        <template #icon>
                            <AllDoneIcon />
                        </template>
                        {{ $t('save-comment') }}
                    </Button>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';
import { computed, ref, watchEffect } from 'vue';
import Button from '../Button/Button.vue';
import TextAreaField from '../Form/TextAreaField.vue';
import AllDoneIcon from '../Icon/AllDoneIcon.vue';
import CalendarEditIcon from '../Icon/CalendarEditIcon.vue';
import DeleteForeverIcon from '../Icon/DeleteForeverIcon.vue';
import EditIcon from '../Icon/EditIcon.vue';
import UndoIcon from '../Icon/UndoIcon.vue';
import Tooltip from '../Tooltip/Tooltip.vue';
import { CommentChangesStatus } from './types';

type DateCommentEntryProps = {
    imageUrl: string;
    name: string;
    dateTime: DateTime;
    text: string;
    isEditable: boolean;
    hasUnsavedChanges: CommentChangesStatus;
};

type DateCommentEntryEmits = {
    (e: 'delete-comment'): void;
    (e: 'update-comment', value: string): void;
    (e: 'undo-deleting-comment'): void;
};

const props = defineProps<DateCommentEntryProps>();
const emit = defineEmits<DateCommentEntryEmits>();

const headlineId = nanoid();
const isInEditMode = ref(false);
const commentValue = ref('');
const hasChanges = computed(() => commentValue.value !== '' && commentValue.value !== props.text);

const hasLoadingLogoFailed = ref(false);
function onImageError(event: Event) {
    hasLoadingLogoFailed.value = true;

    console.error(event);
}
const abortButtonRef = ref();
const editButtonRef = ref();
watchEffect(() => {
    if (isInEditMode.value && abortButtonRef.value) {
        abortButtonRef.value.focus();
    }
});
watchEffect(() => {
    if (!isInEditMode.value && editButtonRef.value) {
        editButtonRef.value.focus();
    }
});

function onCommentChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    commentValue.value = value;
}
</script>
