import { useFluent } from 'fluent-vue';
import { Ref, ref } from 'vue';
import { Section } from '../Timeline.types';

export function useSections(): { sections: Ref<Section[]>; toggleSectionExpanded: (sectionId: string) => void } {
    const fluent = useFluent();

    function toggleSectionExpanded(sectionId: string): void {
        sections.value = sections.value.map((section) => {
            if (section.id === sectionId) {
                return { ...section, isExpanded: !section.isExpanded };
            }

            return { ...section };
        });
    }

    const sections = ref<Section[]>([
        {
            id: 'section-id-hallenausstellungsflächen',
            label: fluent.$t('timeline-section-exhibition-area'),
            type: 'Hallenausstellungsfläche' as const,
            typeCategory: 'Room',
            isExpanded: true,
        },
        {
            id: 'section-id-boulevard-sektionen',
            label: fluent.$t('timeline-section-boulevard-sections'),
            type: 'Boulevard' as const,
            typeCategory: 'Room',
            isExpanded: false,
        },
        {
            id: 'section-id-passagen',
            label: fluent.$t('timeline-section-passage'),
            type: 'Passage' as const,
            typeCategory: 'Room',
            isExpanded: false,
        },
        {
            id: 'section-id-eingänge-zugänge',
            label: fluent.$t('timeline-section-entry'),
            type: 'Eingang' as const,
            typeCategory: 'Room',
            isExpanded: false,
        },
        {
            id: 'section-id-service-center',
            label: fluent.$t('timeline-section-service-center'),
            type: 'Service-Center' as const,
            typeCategory: 'Room',
            isExpanded: false,
        },
        {
            id: 'section-id-konferenzräume',
            label: fluent.$t('timeline-section-conference-rooms'),
            type: 'Konferenzraum' as const,
            typeCategory: 'Room',
            isExpanded: false,
        },
        {
            id: 'section-id-büros-besprecher-intern',
            label: fluent.$t('timeline-section-offices-intern'),
            type: 'Büro/Besprecher (ZBV intern)' as const,
            typeCategory: 'Room',
            isExpanded: false,
        },
        {
            id: 'section-id-lagerräume-intern',
            label: fluent.$t('timeline-section-storage-rooms-intern'),
            type: 'Lagerraum (ZBV intern)' as const,
            typeCategory: 'Room',
            isExpanded: false,
        },
        {
            id: 'section-id-büros-besprecher-extern',
            label: fluent.$t('timeline-section-offices-extern'),
            type: 'Büro/Besprecher (ZBV extern)' as const,
            typeCategory: 'Room',
            isExpanded: false,
        },
        {
            id: 'section-id-lagerräume-extern',
            label: fluent.$t('timeline-section-storage-rooms-extern'),
            type: 'Lagerraum (ZBV extern)' as const,
            typeCategory: 'Room',
            isExpanded: false,
        },
    ]);

    return {
        sections,
        toggleSectionExpanded,
    };
}
