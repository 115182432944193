import { LocationType, LocationTypeCategory } from '@/components/Timeline/Timeline.types';
import { Component, markRaw } from 'vue';
import { $t } from '../../../plugins/fluent';
import BoulevardIcon from '../../Icon/BoulevardIcon.vue';
import BuildingIcon from '../../Icon/BuildingIcon.vue';
import ConferenceIcon from '../../Icon/ConferenceIcon.vue';
import EntryIcon from '../../Icon/EntryIcon.vue';
import ExhibitionAreaIcon from '../../Icon/ExhibitionAreaIcon.vue';
import LayersIcon from '../../Icon/LayersIcon.vue';
import OfficeIcon from '../../Icon/OfficeIcon.vue';
import PartitionIcon from '../../Icon/PartitionIcon.vue';
import PassageIconVue from '../../Icon/PassageIcon.vue';
import RoomIcon from '../../Icon/RoomIcon.vue';
import ServiceCenterIcon from '../../Icon/ServiceCenterIcon.vue';
import StorageRoomIcon from '../../Icon/StorageRoomIcon.vue';

export function getIcon(typeCategory: LocationTypeCategory, type?: LocationType): Component {
    if (type) {
        switch (type) {
            case 'Boulevard':
                return markRaw(BoulevardIcon);

            case 'Konferenzraum':
                return markRaw(ConferenceIcon);

            case 'Eingang':
                return markRaw(EntryIcon);

            case 'Hallenausstellungsfläche':
                return markRaw(ExhibitionAreaIcon);

            case 'Büro/Besprecher (ZBV intern)':
            case 'Büro/Besprecher (ZBV extern)':
                return markRaw(OfficeIcon);

            case 'Passage':
            case 'Passage inkl. Durchfahrtstor':
                return markRaw(PassageIconVue);

            case 'Service-Center':
                return markRaw(ServiceCenterIcon);

            case 'Lagerraum (ZBV intern)':
            case 'Lagerraum (ZBV extern)':
                return markRaw(StorageRoomIcon);
        }
    }

    switch (typeCategory) {
        case 'Building':
            return markRaw(BuildingIcon);

        case 'Floor':
            return markRaw(LayersIcon);

        case 'Room':
            return markRaw(RoomIcon);

        case 'Partition':
            return markRaw(PartitionIcon);

        default:
            throw new Error(`getLocationIcon() failed for unknown type category: ${typeCategory}, type: ${type}`);
    }
}

export function getIconTitle(typeCategory: LocationTypeCategory, type?: LocationType): string {
    if (type) {
        switch (type) {
            case 'Boulevard':
                return $t('boulevard');

            case 'Konferenzraum':
                return $t('conference-room');

            case 'Eingang':
                return $t('entry');

            case 'Hallenausstellungsfläche':
                return $t('exhibition-area');

            case 'Büro/Besprecher (ZBV intern)':
            case 'Büro/Besprecher (ZBV extern)':
                return $t('office');

            case 'Passage':
            case 'Passage inkl. Durchfahrtstor':
                return $t('passage');

            case 'Service-Center':
                return $t('service-center');

            case 'Lagerraum (ZBV intern)':
            case 'Lagerraum (ZBV extern)':
                return $t('storage-room');
        }
    }

    switch (typeCategory) {
        case 'Building':
            return $t('building');

        case 'Floor':
            return $t('floor');

        case 'Room':
            return $t('room');

        case 'Partition':
            return $t('partition');

        default:
            throw new Error(`getLocationIconTitle() failed for unknown type category: ${typeCategory}, type: ${type}`);
    }
}
