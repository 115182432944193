import { MULTILINK, OPTIONAL_STRING, OPTIONAL_YEAR, REQUIRED_STRING } from '@/components/Form/schema';
import { FormErrors } from '@/components/Form/types';
import { z } from 'zod';

export const ProjectSeriesCreateSchema = z.object({
    singleProject: z.boolean().default(false),
    nameShort: z.string().min(1),
    nameLong: z.string().min(1),
});

export type ProjectSeriesCreateSchemaType = z.infer<typeof ProjectSeriesCreateSchema>;
export type ProjectSeriesCreateErrors = FormErrors<ProjectSeriesCreateSchemaType>;

export const ProjectCreateSchema = z.object({
    nameShort: z.string().min(1),
    nameLong: z.string().min(1),
    status: REQUIRED_STRING,
});
export type ProjectCreateSchemaType = z.infer<typeof ProjectCreateSchema>;
export type ProjectCreateErrors = FormErrors<ProjectCreateSchemaType>;

export const ProjectSeriesUpdateSchema = z.object({
    nameShort: REQUIRED_STRING,
    nameLong: OPTIONAL_STRING,
    notes: OPTIONAL_STRING,
    description: OPTIONAL_STRING,
    websites: MULTILINK,
    category: OPTIONAL_STRING,
    type: OPTIONAL_STRING,
    companyGroup: OPTIONAL_STRING,
    businessDomain: OPTIONAL_STRING,
    department: OPTIONAL_STRING,
    organisationalChanges: OPTIONAL_STRING,
    cycle: OPTIONAL_STRING,
    notesCycle: OPTIONAL_STRING,
    notesDayOrder: OPTIONAL_STRING,
    notesAppointment: OPTIONAL_STRING,
    locationRequirements: OPTIONAL_STRING,
    allocationHints: OPTIONAL_STRING,
    otherDocuments: MULTILINK,
    otherSystems: MULTILINK,
    otherLinks: MULTILINK,
});
export type ProjectSeriesUpdateSchemaType = z.infer<typeof ProjectSeriesUpdateSchema>;

export const ProjectSeriesIdentifiersSchema = ProjectSeriesUpdateSchema.pick({
    nameShort: true,
    nameLong: true,
    notes: true,
    description: true,
    // websites: true,
});
export type ProjectSeriesIdentifiersSchemaType = z.infer<typeof ProjectSeriesIdentifiersSchema>;

export const ProjectSeriesTypeDefSchema = ProjectSeriesUpdateSchema.pick({
    type: true,
    category: true,
});
export type ProjectSeriesTypeDefSchemaType = z.infer<typeof ProjectSeriesTypeDefSchema>;

export const ProjectSeriesOrgSchema = ProjectSeriesUpdateSchema.pick({
    companyGroup: true,
    businessDomain: true,
    department: true,
    organisationalChanges: true,
});
export type ProjectSeriesOrgSchemaType = z.infer<typeof ProjectSeriesOrgSchema>;

export const ProjectSeriesCycleSchema = ProjectSeriesUpdateSchema.pick({
    cycle: true,
    notesCycle: true,
});
export type ProjectSeriesCycleSchemaType = z.infer<typeof ProjectSeriesCycleSchema>;

export const ProjectSeriesAppointmentSchema = ProjectSeriesUpdateSchema.pick({
    notesDayOrder: true,
    notesAppointment: true,
});
export type ProjectSeriesAppointmentSchemaType = z.infer<typeof ProjectSeriesAppointmentSchema>;

export const ProjectSeriesLocationSchema = ProjectSeriesUpdateSchema.pick({
    locationRequirements: true,
    allocationHints: true,
});
export type ProjectSeriesLocationSchemaType = z.infer<typeof ProjectSeriesLocationSchema>;

export const ProjectSeriesDocumentsSchema = ProjectSeriesUpdateSchema.pick({
    otherDocuments: true,
});
export type ProjectSeriesDocumentsSchemaType = z.infer<typeof ProjectSeriesDocumentsSchema>;

export const ProjectSeriesSystemsSchema = ProjectSeriesUpdateSchema.pick({
    otherSystems: true,
});
export type ProjectSeriesSystemsSchemaType = z.infer<typeof ProjectSeriesSystemsSchema>;

export const ProjectSeriesOtherLinksSchema = ProjectSeriesUpdateSchema.pick({
    otherLinks: true,
});
export type ProjectSeriesOtherLinksSchemaType = z.infer<typeof ProjectSeriesOtherLinksSchema>;

// PROJECT SCHEMAS

export const ProjectUpdateSchema = z.object({
    nameShort: REQUIRED_STRING,
    nameLong: OPTIONAL_STRING,
    subtitle: OPTIONAL_STRING,
    executionYear: OPTIONAL_YEAR,
    description: OPTIONAL_STRING,
    category: OPTIONAL_STRING,
    type: OPTIONAL_STRING,
    companyGroup: OPTIONAL_STRING,
    businessDomain: OPTIONAL_STRING,
    department: OPTIONAL_STRING,
    organisationalChanges: OPTIONAL_STRING,
    disturbanceNoise: OPTIONAL_STRING,
    disturbanceDust: OPTIONAL_STRING,
    disturbanceOther: OPTIONAL_STRING,
    notesDayOrder: OPTIONAL_STRING,
    notesAppointment: OPTIONAL_STRING,
    locationRequirements: OPTIONAL_STRING,
    allocationHints: OPTIONAL_STRING,
    otherDocuments: MULTILINK,
    otherSystems: MULTILINK,
    otherLinks: MULTILINK,
});
export type ProjectUpdateSchemaType = z.infer<typeof ProjectUpdateSchema>;

export const ProjectIdentifiersSchema = ProjectUpdateSchema.pick({
    nameShort: true,
    nameLong: true,
    subtitle: true,
    executionYear: true,
    description: true,
});
export type ProjectIdentifiersSchemaType = z.infer<typeof ProjectIdentifiersSchema>;

export const ProjectTypeDefSchema = ProjectUpdateSchema.pick({
    type: true,
    category: true,
});
export type ProjectTypeDefSchemaType = z.infer<typeof ProjectTypeDefSchema>;

export const ProjectDisturbanceSchema = ProjectUpdateSchema.pick({
    disturbanceNoise: true,
    disturbanceDust: true,
    disturbanceOther: true,
});
export type ProjectDisturbanceSchemaType = z.infer<typeof ProjectDisturbanceSchema>;

export const ProjectOrgSchema = ProjectUpdateSchema.pick({
    companyGroup: true,
    businessDomain: true,
    department: true,
    organisationalChanges: true,
});
export type ProjectOrgSchemaType = z.infer<typeof ProjectOrgSchema>;

export const ProjectAppointmentSchema = ProjectUpdateSchema.pick({
    notesDayOrder: true,
    notesAppointment: true,
});
export type ProjectAppointmentSchemaType = z.infer<typeof ProjectAppointmentSchema>;

export const ProjectLocationSchema = ProjectUpdateSchema.pick({
    locationRequirements: true,
    allocationHints: true,
});
export type ProjectLocationSchemaType = z.infer<typeof ProjectLocationSchema>;

export const ProjectDocumentsSchema = ProjectUpdateSchema.pick({
    otherDocuments: true,
});
export type ProjectDocumentsSchemaType = z.infer<typeof ProjectDocumentsSchema>;

export const ProjectSystemsSchema = ProjectUpdateSchema.pick({
    otherSystems: true,
});
export type ProjectSystemsSchemaType = z.infer<typeof ProjectSystemsSchema>;

export const ProjectOtherLinksSchema = ProjectUpdateSchema.pick({
    otherLinks: true,
});
export type ProjectOtherLinksSchemaType = z.infer<typeof ProjectOtherLinksSchema>;
