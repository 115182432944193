<template>
    <TimelineView
        :site-name="props.siteName"
        :site-link-target="props.siteLinkTarget"
        :site-conflicts-unresolved-count="totalConflictsCount - resolvedConflictsCount"
        :site-has-conflicts="totalConflictsCount > 0"
        :site-has-unresolved-conflicts="totalConflictsCount - resolvedConflictsCount > 0"
        :site-has-locations="locations.length > 0"
        :site-has-external-events="externalEvents.length > 0"
        :external-events="externalEvents"
        :external-events-link-target="props.externalEventsLinkTarget"
        :header="header"
        :previous-period-label="previousPeriodLabel"
        :is-previous-period-disabled="isPreviousPeriodDisabled"
        :next-period-label="nextPeriodLabel"
        :is-next-period-disabled="isNextPeriodDisabled"
        :row-height-style="rowHeightStyle"
        :allocation-variant-id="props.allocationVariantId"
        :current-allocation-variant-label="props.currentAllocationVariantLabel"
        @toggle-location-expanded="toggleLocationExpanded"
        @toggle-section-expanded="(sectionId) => $emit('toggle-section-expanded', sectionId)"
        @key-press="keyPress"
        @expand-to-next-month="$emit('expand-to-next-month')"
        @expand-to-previous-month="$emit('expand-to-previous-month')"
    >
        <template #site-icon>
            <slot name="site-icon" />
        </template>
    </TimelineView>
</template>

<script setup lang="ts">
import { useFluent } from 'fluent-vue';
import { DateTime } from 'luxon';
import { computed, provide, ref, toRef, watchEffect } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { useTimelineStore } from './Timeline.store';
import {
    Allocation,
    AllocationPhaseUpdate,
    AllocationType,
    Conflict,
    EnrichedAllocation,
    ExternalEvent,
    Location,
    LocationWithAllDayProperties,
    Section,
} from './Timeline.types';
import { TimelineExternalEventDay } from './TimelineView.types';
import { AllocationPhaseInput } from './allocation/types';
import TimelineView from './components/TimelineView.vue';
import {
    emitCreateAllocationKey,
    emitDeleteAllocationKey,
    emitUpdatePhasesKey,
    triggerTooltipPositioningKey,
} from './injectionKeys';
import {
    countAllocations,
    enrichAllocations,
    validateAllocationDates,
    validateAllocationsAreUnique,
} from './logic/allocations';
import { calculateConflicts } from './logic/conflicts';
import { calculateExternalEventDays, countExternalEventsPerDay } from './logic/externalEvents';
import { calculateHeaderCalendarWeeks, calculateHeaderDays, calculateHeaderMonths } from './logic/header';
import {
    calculateDayProperties,
    flattenLocations,
    groupLocationsBySection,
    validateLocations,
} from './logic/locations';
const { $t } = useFluent();

type TimelineProps = {
    siteName: string;
    siteLinkTarget?: RouteLocationRaw;
    startDateTime: DateTime;
    endDateTime: DateTime;
    previousMonthButton: 'Visible' | 'Disabled' | 'Hidden';
    nextMonthButton: 'Visible' | 'Disabled' | 'Hidden';
    locations: Location[];
    allocations: Allocation[];
    externalEvents: ExternalEvent[];
    externalEventsLinkTarget?: RouteLocationRaw;
    sections?: Section[];
    allocationVariantId: string;
    currentAllocationVariantLabel: string | null;
    hasCreateButtons: boolean;
};

const props = defineProps<TimelineProps>();

type TimelineEmits = {
    (e: 'toggle-section-expanded', sectionId: string): void;
    (
        e: 'create-allocation',
        locationId: string,
        startDateTime: DateTime,
        endDateTime: DateTime,
        allocationVariantId: string,
        phases: AllocationPhaseInput[],
        onDone: (isSuccess: boolean) => void,
    ): void;
    (e: 'delete-allocation', allocationId: string, onDone: (isSuccess: boolean) => void): void;
    (
        e: 'update-phases',
        phases: AllocationPhaseUpdate[],
        initialStartDateTime: DateTime,
        initialEndDateTime: DateTime,
        newLocationIds: string[],
        allocationId: string,
        allocationType: AllocationType,
        allocationVariantId: string,
        onDone: (isSuccess: boolean, phaseUpdates: AllocationPhaseUpdate[]) => void,
    ): void;
    (e: 'expand-to-previous-month'): void;
    (e: 'expand-to-next-month'): void;
};
const emit = defineEmits<TimelineEmits>();

watchEffect(() => {
    validateAllocationDates(props.allocations);
    validateAllocationsAreUnique(props.allocations);
});

const durationInDays = computed<number>(() => props.endDateTime.diff(props.startDateTime, 'days').days + 1);
const allocations = computed<EnrichedAllocation[]>(() => enrichAllocations(props.allocations));
const conflicts = computed<Conflict[]>(() =>
    calculateConflicts(allocations.value, props.startDateTime, durationInDays.value),
);
const locationsRef = toRef(props, 'locations');
const locationsInternal = ref<LocationWithAllDayProperties[]>([]);
watchEffect(() => {
    validateLocations(locationsRef.value, props.sections);

    locationsInternal.value = calculateDayProperties(
        locationsRef.value,
        allocations.value,
        conflicts.value,
        props.startDateTime,
        props.endDateTime,
        durationInDays.value,
        props.hasCreateButtons,
    );
});

const externalEvents = computed<TimelineExternalEventDay[]>(() =>
    calculateExternalEventDays(props.externalEvents, props.startDateTime, props.endDateTime, durationInDays.value),
);
const totalConflictsCount = computed(() => conflicts.value.length);
const resolvedConflictsCount = computed(() => {
    return conflicts.value.filter((conflict) => conflict.status === 'resolved').length;
});
const header = computed(() => {
    return {
        days: calculateHeaderDays(conflicts.value, props.startDateTime, durationInDays.value),
        calendarWeeks: calculateHeaderCalendarWeeks(props.startDateTime, durationInDays.value),
        months: calculateHeaderMonths(props.startDateTime, durationInDays.value),
    };
});

/**
 * The TimelineView calculation works in 3 steps:
 * 1) Calculate all properties for ALL location levels + sections
 * 2) Select/Aggregate the required properties for the actually visible locations/sections
 * 3) Group locations in at least one section. The timeline view displays 1 section without labels OR multiple sections with labels (defined via the sections prop)
 * 4) Pass options into pinia store to calculate additional properties
 *
 * This is step 2.
 */
const flattenedLocations = computed(() => flattenLocations(locationsInternal.value));

/**
 * ... and step 3.
 */
const sectionsInternal = computed(() => groupLocationsBySection(flattenedLocations.value, props.sections ?? []));

/**
 * ... and step 4.
 */
const store = useTimelineStore();
// TODO Move all of the Timeline logic into the Pinia store to avoid all this manual patching
watchEffect(() => {
    store.$patch({ sections: sectionsInternal.value });
});

const maxAllocationsPerVisibleLocation = computed(() => countAllocations(store.sectionsWithCheckboxes));
const maxExternalEventsPerDay = computed(() => countExternalEventsPerDay(externalEvents.value));

const previousPeriodLabel = computed(() => {
    switch (props.previousMonthButton) {
        case 'Visible':
        case 'Disabled': {
            const previousMonth = props.startDateTime.minus({ months: 1 });

            return $t('month-with-year', { month: previousMonth.month, yearString: String(previousMonth.year) });
        }

        case 'Hidden':
        default:
            return null;
    }
});
const isPreviousPeriodDisabled = computed(() => props.previousMonthButton === 'Disabled');

const nextPeriodLabel = computed(() => {
    switch (props.nextMonthButton) {
        case 'Visible':
        case 'Disabled': {
            const nextMonth = props.endDateTime.plus({ months: 1 });

            return $t('month-with-year', { month: nextMonth.month, yearString: String(nextMonth.year) });
        }

        case 'Hidden':
        default:
            return null;
    }
});
const isNextPeriodDisabled = computed(() => props.nextMonthButton === 'Disabled');

const rowHeightStyle = computed(() => {
    // Allocation rows can (optionally) include a button to insert a new allocation, the external event row can't
    const rows = Math.max(
        maxAllocationsPerVisibleLocation.value + (props.hasCreateButtons ? 1 : 0),
        maxExternalEventsPerDay.value,
        2,
    );
    const heightAllocationInRem = 0.875; // 12px height + 2px wrapper

    // Allocations + 1px between + 1px above and under all allocations
    return {
        height: `${rows * heightAllocationInRem + (rows - 1) * 0.0625 + 0.125}rem`,
    };
});

function setIsExpandedOnNestedLocations(
    locations: LocationWithAllDayProperties[],
    isExpanded: boolean,
): LocationWithAllDayProperties[] {
    return locations.map((location) => {
        const nestedLocations = setIsExpandedOnNestedLocations(location.nestedLocations, isExpanded);

        return {
            ...location,
            isExpanded: false,
            nestedLocations,
        };
    });
}

function toggleLocationExpandedRecursively(
    locations: LocationWithAllDayProperties[],
    locationId: string,
    forcedValue?: boolean,
): LocationWithAllDayProperties[] {
    return locations.map((location) => {
        const newValue = forcedValue === undefined ? !location.isExpanded : forcedValue;

        if (location.id === locationId) {
            const nestedLocations = setIsExpandedOnNestedLocations(location.nestedLocations, newValue);

            return {
                ...location,
                nestedLocations,
                isExpanded: newValue,
            };
        } else {
            return {
                ...location,
                nestedLocations: toggleLocationExpandedRecursively(location.nestedLocations, locationId, forcedValue),
            };
        }
    });
}

function toggleLocationExpanded(locationId: string, forcedValue?: boolean): void {
    locationsInternal.value = toggleLocationExpandedRecursively(locationsInternal.value, locationId, forcedValue);
    triggerTooltipPositioning();
}

function keyPress(locationId: string, event: KeyboardEvent): void {
    switch (event.key) {
        case 'ArrowRight':
            toggleLocationExpanded(locationId, true);
            event.preventDefault();
            break;

        case 'ArrowLeft':
            toggleLocationExpanded(locationId, false);
            event.preventDefault();
            break;
    }
}

// This is used to force repositioning updates on tooltips within the Timeline component when this values changes
const triggerTooltipPositioningCounter = ref(0);
provide(triggerTooltipPositioningKey, triggerTooltipPositioningCounter);
function triggerTooltipPositioning() {
    triggerTooltipPositioningCounter.value = triggerTooltipPositioningCounter.value + 1;
}

// Avoid prop drilling for emits from deep-nested components
provide(emitCreateAllocationKey, (...args) => emit('create-allocation', ...args));
provide(emitDeleteAllocationKey, (...args) => emit('delete-allocation', ...args));
provide(emitUpdatePhasesKey, (...args) => emit('update-phases', ...args));
</script>
