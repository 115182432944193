import { getSectionId } from '@/event/event';
import { calculateTimelineEnd, calculateTimelineStart } from '@/event/tabs/event/timelineRange';
import { TimelineProjectVariantFragment, TimelineRoomFragment } from '@/generated/graphql';
import { routeForLocation } from '@/location/location';
import { computed, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { AllocationPhaseUpdate, LocationType, LocationTypeCategory } from './Timeline.types';
import { TimelineRoomResponse, TimelineVariant } from './allocation/types';

export function getTransparencyVariant(highlightedVariantId: string, variantId: string, isCurrent: boolean) {
    if (highlightedVariantId === variantId || highlightedVariantId === 'No-Highlight-Id') {
        if (isCurrent) {
            return 'Solid' as const;
        } else {
            return 'Outer-Semi-Transparent' as const;
        }
    }
    return 'Semi-Transparent' as const;
}

export function useTimelineStartAndEnd(variantsRef: Ref<TimelineProjectVariantFragment[]>, executionYear: Ref<number>) {
    const monthsBefore = ref(0); // Quarters before calculated start DateTime
    const monthsAfter = ref(0); // Quarters after calculated end DateTime

    function onExpandToPreviousMonth() {
        monthsBefore.value = monthsBefore.value + 1;
    }

    function onExpandToNextMonth() {
        monthsAfter.value = monthsAfter.value + 1;
    }

    const calculatedTimelineStart = computed(() => calculateTimelineStart(executionYear.value, variantsRef.value));
    const calculatedTimelineEnd = computed(() => calculateTimelineEnd(executionYear.value, variantsRef.value));

    const timelineStartDateTime = computed(() =>
        calculatedTimelineStart.value.minus({
            months: monthsBefore.value,
        }),
    );
    const timelineEndDateTime = computed(() =>
        calculatedTimelineEnd.value.plus({
            months: monthsAfter.value,
        }),
    );

    return {
        timelineStartDateTime,
        timelineEndDateTime,
        onExpandToPreviousMonth,
        onExpandToNextMonth,
    };
}

export function useTimelineLocations(rooms: Ref<TimelineRoomFragment[]>) {
    const locations = computed(
        () =>
            rooms.value.map((r) => ({
                id: `room-${r.id}`,
                label: r.nameShort,
                linkTarget: routeForLocation('Room', r.id),
                level: 0,
                type: r.type?.name as LocationType,
                typeCategory: 'Room' as LocationTypeCategory,
                isLeafLocation: true,
                sectionId: getSectionId(r.type?.name ?? 'no-room-type'),
                nestedLocations: [],
            })) ?? [],
    );

    return {
        locations,
    };
}

export function validateAllocationUpdateResponse(
    allocation: TimelineRoomResponse | null | undefined,
    remainingPhases: (AllocationPhaseUpdate & { dayOffsetCount: number })[],
) {
    if (!allocation) {
        throw new Error('No response from allocation update');
    }
    const phases = allocation.phases.nodes;
    if (!phases) {
        throw new Error('Updated phases are missing in response');
    }
    if (phases.length !== remainingPhases.length) {
        throw new Error('Unexpected mismatch of frontend phases length and updated phases in response');
    }

    const updatedPhasesResponse = allocation.phases.nodes;

    const confirmedPhases = remainingPhases.map((phase, index) => {
        const specialization =
            phase.newSpecialization !== phase.specialization ? phase.newSpecialization : phase.specialization;
        return {
            ...phase,
            id: updatedPhasesResponse[index].id,
            cellSpan: phase.cellSpanBefore + phase.cellSpan + phase.cellSpanAfter,
            cellSpanBefore: 0,
            cellSpanAfter: 0,
            specialization,
        };
    });

    return confirmedPhases;
}

export function useTimelineRouteParams(variantsRef: Ref<TimelineVariant[]>) {
    const route = useRoute();

    const visibleIdsFromRoute = computed(() => {
        if ('visible' in route.query) {
            const indexString = route.query.visible as string;
            const visibleIndexes = indexString.split(',').map((s) => Number(s));
            return variantsRef.value.filter((v) => visibleIndexes.includes(v.index)).map((v) => v.id);
        }
        return [];
    });

    const highlightedIdFromRoute = computed(() => {
        if ('highlight' in route.query) {
            const index = Number(route.query.highlight as string);
            return variantsRef.value.find((v) => v.index === index)?.id ?? 'No-Highlight-Id';
        }
        return 'No-Highlight-Id';
    });

    return {
        visibleIdsFromRoute,
        highlightedIdFromRoute,
    };
}
