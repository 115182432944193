<template>
    <div class="relative flex">
        <div class="flex flex-row gap-[0.0625rem]">
            <!-- Inserted before parts -->
            <AllocationPart
                v-for="(part, partIndex) in insertedPhasesBefore"
                :key="`${part.form}-${partIndex}`"
                :type="props.type"
                :form="part.form"
                :is-phase-end="partIndex === props.cellSpanBefore && props.confirmedCellSpan === 0"
                :variant="'Inserted'"
                opacity-variant="Solid"
                :specialization="null"
                :has-temporary-change-marker="false"
            />

            <!-- Confirmed parts -->
            <AllocationPart
                v-for="(part, partIndex) in confirmedAllocationParts"
                :key="`${part.form}-${partIndex}`"
                :type="props.type"
                :form="part.form"
                :is-phase-end="partIndex === props.confirmedCellSpan - 1 && props.cellSpanAfter <= 0"
                :variant="part.variant"
                opacity-variant="Solid"
                :specialization="props.selectedSpecialization"
                :has-temporary-change-marker="props.hasTemporaryChangeMarker"
            />

            <!-- Inserted after parts -->
            <AllocationPart
                v-for="(part, partIndex) in insertedPhasesAfter"
                :key="`${part.form}-${part}`"
                :type="props.type"
                :form="part.form"
                :is-phase-end="partIndex === props.cellSpanAfter - 1"
                :variant="'Inserted'"
                opacity-variant="Solid"
                :specialization="null"
                :has-temporary-change-marker="false"
            />
        </div>

        <InsertPhaseButton
            class="absolute -left-[6.5px]"
            :class="props.isLeftEndPosition ? 'top-1.5' : 'top-3'"
            :bar-height="props.isLeftEndPosition ? 'Full' : 'Small'"
            :phase-name="props.insertPhaseBefore"
            @click="$emit('insertPhaseBefore')"
        />

        <!-- Wrapper for confirmed phases -->
        <div class="absolute top-0 bottom-0" :style="{ ...wrapperConfirmedLeftStyle, ...wrapperConfirmedRightStyle }">
            <EditPhaseWidget
                class="absolute -top-[1.4375rem]"
                :class="[leftClass]"
                :phase-type="props.type"
                :direction="directionLeftEditWidget"
                :is-decreasing-deleting="
                    props.cellSpanBefore + props.confirmedCellSpan + props.cellSpanAfter <= 1 &&
                    props.position !== 'Main'
                "
                :is-decrease-button-disabled="
                    props.cellSpanBefore + props.confirmedCellSpan + props.cellSpanAfter === 0
                "
                :has-change-phase-button="
                    props.position !== 'Main' || (props.position === 'Main' && props.confirmedCellSpan <= 1)
                "
                :cell-span-before="props.cellSpanBefore"
                :cell-span-after="props.cellSpanAfter"
                :change-phase-status="props.isChangeTypePopupOpen ? 'Close-Popup' : 'Open-Popup'"
                :is-change-type-popup-open="
                    props.isChangeTypePopupOpen &&
                    ((props.position === 'Main' && props.confirmedCellSpan === 1) || props.position !== 'Main')
                "
                :allowed-specializations="props.allowedSpecializations"
                :selected-specialization="
                    ((props.position !== 'Main' || (props.position === 'Main' && props.confirmedCellSpan <= 1)) &&
                        props.selectedSpecialization) ||
                    null
                "
                :has-temporary-change-marker="
                    (props.position !== 'Main' || (props.position === 'Main' && props.confirmedCellSpan <= 1)) &&
                    props.hasTemporaryChangeMarker
                "
                @decrease-phase-length="
                    props.position === 'Before-Main' || props.position === 'Main'
                        ? $emit('decreasePhaseLengthBefore')
                        : $emit('decreasePhaseLengthAfter')
                "
                @increase-phase-length="
                    (direction) =>
                        direction === 'Left' ? $emit('increasePhaseLengthBefore') : $emit('increasePhaseLengthAfter')
                "
                @toggle-change-phase-type="
                    props.isChangeTypePopupOpen ? $emit('hide-change-type-popup') : $emit('show-change-type-popup')
                "
                @change-specialization="(specialization) => $emit('change-specialization', specialization)"
            />

            <ChangePhaseButton
                v-if="props.position === 'Main' && props.confirmedCellSpan > 1"
                class="absolute -top-[0.1875rem] left-[50%] -translate-x-[50%]"
                :phase-type="props.type"
                :change-phase-status="props.isChangeTypePopupOpen ? 'Close-Popup' : 'Open-Popup'"
                :is-change-type-popup-open="
                    props.isChangeTypePopupOpen && props.position === 'Main' && props.confirmedCellSpan > 1
                "
                :specializations="props.allowedSpecializations"
                :selected-specialization="props.selectedSpecialization"
                :has-temporary-change-marker="props.hasTemporaryChangeMarker"
                @toggle-change-phase-button="
                    props.isChangeTypePopupOpen ? $emit('hide-change-type-popup') : $emit('show-change-type-popup')
                "
                @change-specialization="(specialization) => $emit('change-specialization', specialization)"
            />

            <EditPhaseWidget
                v-if="props.confirmedCellSpan >= 2 && props.position === 'Main'"
                class="absolute -top-[1.4375rem]"
                :class="[rightClass]"
                :phase-type="props.type"
                direction="Right"
                :is-decreasing-deleting="false"
                :is-decrease-button-disabled="
                    props.cellSpanBefore + props.confirmedCellSpan + props.cellSpanAfter === 0
                "
                :has-change-phase-button="props.confirmedCellSpan <= 1"
                :cell-span-before="props.cellSpanBefore"
                :cell-span-after="props.cellSpanAfter"
                change-phase-status="Dont-Show"
                :is-change-type-popup-open="false"
                :allowed-specializations="props.allowedSpecializations"
                :selected-specialization="
                    ((props.position !== 'Main' || (props.position === 'Main' && props.confirmedCellSpan <= 1)) &&
                        props.selectedSpecialization) ||
                    null
                "
                :has-temporary-change-marker="
                    (props.position !== 'Main' || (props.position === 'Main' && props.confirmedCellSpan <= 1)) &&
                    props.hasTemporaryChangeMarker
                "
                @decrease-phase-length="$emit('decreasePhaseLengthAfter')"
                @increase-phase-length="$emit('increasePhaseLengthAfter')"
            />
        </div>

        <InsertPhaseButton
            v-if="isRightEndPosition"
            class="absolute -right-[6.5px] top-1.5"
            bar-height="Full"
            :phase-name="props.insertPhaseAfter"
            @click="$emit('insertPhaseAfter')"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, watchEffect } from 'vue';
import { PhaseType } from '../Timeline.types';
import AllocationPart from '../allocation/AllocationPart.vue';
import { SpecializationType } from '../allocation/SpecializationMappings';
import ChangePhaseButton from '../edit-popup/ChangePhaseButton.vue';
import EditPhaseWidget from '../edit-popup/EditPhaseWidget.vue';
import InsertPhaseButton from '../edit-popup/InsertPhaseButton.vue';
import { columnWidthInRem } from '../sharedStyles';
import {
    calculateFormsForConfirmedAllocationParts,
    calculateInsertedPhases,
    calculateVariantsForConfirmedAllocationParts,
} from './logic';

type PhaseGroupProps = {
    type: PhaseType;
    cellSpanBefore: number; // Positive number ==> Insert cells, negative number ==> Remove cells
    confirmedCellSpan: number;
    cellSpanAfter: number; // Positive number ==> Insert cells, negative number ==> Remove cells
    isLeftEndPosition: boolean;
    isRightEndPosition: boolean;
    insertPhaseBefore: string | null; // string === Name of phase, null === Button is disabled
    insertPhaseAfter: string | null; // string === Name of phase, null === Button is disabled
    position: 'Before-Main' | 'Main' | 'After-Main';
    isChangeTypePopupOpen: boolean;
    allowedSpecializations: (SpecializationType | null)[];
    selectedSpecialization: SpecializationType | null;
    hasTemporaryChangeMarker: boolean;
};

const props = defineProps<PhaseGroupProps>();

defineEmits<{
    (e: 'insertPhaseBefore'): void;
    (e: 'insertPhaseAfter'): void;
    (e: 'decreasePhaseLengthBefore'): void;
    (e: 'decreasePhaseLengthAfter'): void;
    (e: 'increasePhaseLengthBefore'): void;
    (e: 'increasePhaseLengthAfter'): void;
    (e: 'show-change-type-popup'): void;
    (e: 'hide-change-type-popup'): void;
    (e: 'change-specialization', specialization: SpecializationType | null): void;
}>();

function validateProps(props: PhaseGroupProps) {
    if (Math.max(props.cellSpanBefore, props.confirmedCellSpan, props.cellSpanAfter, 0) < 1) {
        throw new Error(
            `Cell span must be >= 1, got cell span before: ${props.cellSpanBefore}, confirmed cell span: ${props.confirmedCellSpan}, cell span after: ${props.cellSpanAfter}`,
        );
    }
}

watchEffect(() => {
    validateProps(props);
});

const formsForConfirmedAllocationParts = computed(() =>
    calculateFormsForConfirmedAllocationParts(
        props.confirmedCellSpan,
        props.cellSpanBefore,
        props.cellSpanAfter,
        props.isLeftEndPosition,
        props.isRightEndPosition,
    ),
);

const confirmedAllocationParts = computed(() =>
    calculateVariantsForConfirmedAllocationParts(
        formsForConfirmedAllocationParts.value,
        props.cellSpanBefore,
        props.cellSpanAfter,
        props.position,
    ),
);

const insertedPhasesBefore = computed(() =>
    calculateInsertedPhases(props.cellSpanBefore, props.isLeftEndPosition, false),
);
const insertedPhasesAfter = computed(() =>
    calculateInsertedPhases(props.cellSpanAfter, false, props.isRightEndPosition),
);

const directionLeftEditWidget = computed(() => {
    switch (props.position) {
        case 'Before-Main':
            return 'Left';

        case 'Main': {
            if (props.confirmedCellSpan === 1) {
                return 'Both';
            }

            return 'Left';
        }

        case 'After-Main':
            return 'Right';

        default: {
            throw new Error(`Unexpected position: ${props.position}`);
        }
    }
});

const leftClass = computed(() => {
    if (props.cellSpanBefore > 0 && props.confirmedCellSpan === 0) {
        return 'right-0';
    }

    if (props.cellSpanAfter > 0 && props.confirmedCellSpan === 0) {
        return 'left-0';
    }

    if (props.confirmedCellSpan === 1 || (props.confirmedCellSpan === 2 && props.position === 'Main')) {
        return 'left-0';
    }

    if (props.position === 'Main') {
        return 'left-2';
    }

    return 'left-[50%] -translate-x-[50%]';
});

const rightClass = computed(() => {
    if (props.confirmedCellSpan <= 2) {
        return 'right-0';
    }

    return 'right-2';
});

const wrapperConfirmedLeftStyle = computed(() => {
    if (props.cellSpanBefore > 0 && props.confirmedCellSpan > 0) {
        return { left: `${(columnWidthInRem + 0.0625) * props.cellSpanBefore}rem` };
    }

    return {
        left: 0,
    };
});

const wrapperConfirmedRightStyle = computed(() => {
    if (props.cellSpanAfter > 0 && props.confirmedCellSpan > 0) {
        return { right: `${(columnWidthInRem + 0.0625) * props.cellSpanAfter}rem` };
    }

    return {
        right: 0,
    };
});
</script>
