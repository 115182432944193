import {
    EventAllocationRoomPhaseCreateInput,
    ProjectAllocationRoomPhaseCreateInput,
    TimelineVariant,
} from '@/components/Timeline/allocation/types';
import { getDurationInDays } from '@/components/Timeline/logic/allocations';
import { AllocationType } from '@/components/Timeline/Timeline.types';
import {
    CreateEventVariantDocument,
    CreateEventVariantWithRoomsDocument,
    CreateProjectVariantDocument,
    CreateProjectVariantWithRoomsDocument,
} from '@/generated/graphql';
import { FetchResult } from '@apollo/client';
import { useMutation } from '@vue/apollo-composable';
import { computed, Ref, toValue } from 'vue';

export function useCreateNewVariant(
    variantsRef: Ref<TimelineVariant[]>,
    setVariantsRef: (value: TimelineVariant[]) => void,
    visibleVariants: Ref<string[]>,
    setVisibleVariants: (value: string[]) => void,
    allocationType: AllocationType,
    objectId: Ref<string>,
) {
    const newVariantIndex = computed(() => (variantsRef.value.reduce((max, v) => Math.max(max, v.index), 0) ?? 0) + 1);

    const mutations = {
        Event: {
            create: useMutation(CreateEventVariantDocument),
            createFrom: useMutation(CreateEventVariantWithRoomsDocument),
        },
        Project: {
            create: useMutation(CreateProjectVariantDocument),
            createFrom: useMutation(CreateProjectVariantWithRoomsDocument),
        },
        None: {
            create: undefined,
            createFrom: undefined,
        },
    };

    function handleResult(result: FetchResult | null, onDone: (isSuccess: boolean) => void) {
        if (result?.errors) {
            onDone(false);
            throw new Error(`Could not create new variant: ${result.errors}`);
        }
        const resultVariant = result?.data?.create?.variant;
        if (resultVariant) {
            setVariantsRef([...variantsRef.value, resultVariant]);
            setVisibleVariants([...visibleVariants.value, resultVariant.id]);
            onDone(true);
        }
    }

    async function onCreateNewVariant(params: {
        allocationVariantLabel: string;
        copyAllocationsFrom: string | null;
        onDone: (isSuccess: boolean) => void;
    }) {
        const { allocationVariantLabel, copyAllocationsFrom, onDone } = params;

        if (copyAllocationsFrom) {
            const fromVariant = variantsRef.value.find((v) => v.id === copyAllocationsFrom);
            if (!fromVariant) {
                throw new Error('Variant to copy from, does not exist');
            }
            let rooms: EventAllocationRoomPhaseCreateInput[] | ProjectAllocationRoomPhaseCreateInput[] = [];
            if (allocationType === 'Event') {
                rooms = fromVariant.rooms.nodes.map((room) => ({
                    roomId: room.roomId,
                    start: room.start,
                    end: room.end,
                    allocationRoomPhasesUsingId: {
                        create: room.phases.nodes.map((p) => ({
                            usageId: p.usage.id,
                            specializationType: p.specializationType ?? '',
                            duration: { days: getDurationInDays(p.duration) },
                            offset: { days: getDurationInDays(p.offset) },
                        })),
                    },
                }));
            } else if (allocationType === 'Project') {
                rooms = fromVariant.rooms.nodes.map((room) => ({
                    roomId: room.roomId,
                    start: room.start,
                    end: room.end,
                    projectAllocationRoomPhasesUsingId: {
                        create: room.phases.nodes.map((p) => ({
                            usageId: p.usage.id,
                            specializationType: p.specializationType ?? '',
                            duration: { days: getDurationInDays(p.duration) },
                            offset: { days: getDurationInDays(p.offset) },
                        })),
                    },
                }));
            }

            const createVariantFromMutation = mutations[allocationType].createFrom;
            if (createVariantFromMutation) {
                const result = await createVariantFromMutation.mutate({
                    objectId: toValue(objectId),
                    name: allocationVariantLabel,
                    index: newVariantIndex.value,
                    rooms,
                });
                handleResult(result, onDone);
            }
        } else {
            const createVariantMutation = mutations[allocationType].create;
            if (createVariantMutation) {
                const result = await createVariantMutation.mutate({
                    id: toValue(objectId),
                    name: allocationVariantLabel,
                    index: newVariantIndex.value,
                });
                handleResult(result, onDone);
            }
        }
    }

    return {
        newVariantIndex,
        onCreateNewVariant,
    };
}
