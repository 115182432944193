<template>
    <BaseForm>
        <template #fields>
            <ComboboxField
                :value="selectedCategory"
                :label="$t('event-category')"
                :error-messages="errors.category"
                :items="CATEGORY_ITEMS"
                @change="handleConnectedCombobox($event, 'category')"
            />
            <ComboboxField
                :value="selectedType"
                :label="$t('event-type')"
                :error-messages="errors.type"
                :items="filteredTypeItems"
                @change="$emit('handleSelectChange', { item: $event, fieldName: 'type' })"
            />
            <CheckboxField
                :value="series.isPublic"
                :label="$t('is-public')"
                :error-messages="errors.isPublic"
                @input="$emit('handleCheckboxInput', { event: $event, fieldName: 'isPublic' })"
            />
            <CheckboxField
                :value="series.isTechnical"
                :label="$t('is-technical')"
                :error-messages="errors.isTechnical"
                @input="$emit('handleCheckboxInput', { event: $event, fieldName: 'isTechnical' })"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import CheckboxField from '@/components/Form/CheckboxField.vue';
import ComboboxField, { ComboboxItem } from '@/components/Form/ComboboxField.vue';
import { CATEGORY_ITEMS, CAT_TYPE_MAPPING, TYPE_ITEMS } from '@/event/forms/schemas';
import { EventSeriesDetails, EventSeriesDetailsErrors } from '@/event/types';
import { computed } from 'vue';

const props = defineProps<{ series: EventSeriesDetails; errors: EventSeriesDetailsErrors }>();
const emit = defineEmits<{
    (e: 'handleSelectChange', v: { item: ComboboxItem | null; fieldName: string }): void;
    (e: 'handleCheckboxInput', v: { event: Event; fieldName: string }): void;
}>();

const selectedCategory = computed(() => CATEGORY_ITEMS.find((i) => i.name === props.series.category) ?? null);
const filteredTypeItems = computed(() => {
    const catId = selectedCategory.value?.id;
    if (catId && catId !== '0') {
        const typeIds = CAT_TYPE_MAPPING[catId];
        return TYPE_ITEMS.filter((i) => typeIds.indexOf(i.id) >= 0);
    }
    return TYPE_ITEMS;
});
const selectedType = computed(() => filteredTypeItems.value.find((i) => i.id === props.series.type) ?? null);

function handleConnectedCombobox(item: ComboboxItem | null, fieldName: string) {
    emit('handleSelectChange', { item, fieldName });
    // update type select because of different selectable Items based on category
    emit('handleSelectChange', { item: selectedType.value, fieldName: 'type' });
}
</script>
