<template>
    <div
        class="h-3 flex flex-row items-center"
        :class="[
            backgroundColorClass,
            opacityClasses,
            borderClasses,
            formClasses,
            phaseConnectorBarClasses,
            columnWidthClass,
            { relative: Boolean(props.specialization || props.hasTemporaryChangeMarker) },
        ]"
    >
        <div class="absolute top-0 left-[11.5px]" :class="[textColorClass]">
            <!-- Event icons inside -->
            <ClothesHangerIcon v-if="specializationIcon === 'ClothesHanger'" class="size-3" />
            <DoorBackIcon v-if="specializationIcon === 'DoorBack'" class="size-3" />
            <GroupIcon v-if="specializationIcon === 'Group'" class="size-3" />
            <InfoIcon v-if="specializationIcon === 'Info'" class="size-3" />
            <PackageIcon v-if="specializationIcon === 'Package'" class="size-3" />
            <ParkingIcon v-if="specializationIcon === 'Parking'" class="size-3" />
            <RestaurantIcon v-if="specializationIcon === 'Restaurant'" class="size-3" />
            <TruckIcon v-if="specializationIcon === 'Truck'" class="size-3" />
            <WalkingIcon v-if="specializationIcon === 'Walking'" class="size-3" />

            <!-- Event icons outside -->
            <CenterPointIcon v-if="specializationIcon === 'CenterPoint'" class="size-3" />
            <DirectionsRunIcon v-if="specializationIcon === 'DirectionsRun'" class="size-3" />
            <StoreFrontIcon v-if="specializationIcon === 'StoreFront'" class="size-3" />

            <!-- Project icons -->
            <EngineeringIcon v-if="specializationIcon === 'Engineering'" class="size-3" />
            <ConstructionIcon v-if="specializationIcon === 'Construction'" class="size-3" />
            <DangerousIcon v-if="specializationIcon === 'Dangerous'" class="size-3" />
        </div>

        <div
            v-if="props.hasTemporaryChangeMarker"
            class="w-[0.375rem] h-[0.375rem] absolute bg-red-700 rounded-full left-[1.25rem] top-[0.0625rem] shadow-[0_0_0.0625rem_0.0625rem] shadow-red-50/50"
        />

        <div
            v-if="props.opacityVariant === 'Outer-Semi-Transparent'"
            class="h-1.5 w-full opacity-100"
            :class="[
                backgroundColorClass,
                {
                    'ml-[0.1875rem] rounded-l': props.form === 'Start' || props.form === 'Single',
                    'mr-[0.1875rem] rounded-r': props.form === 'End' || props.form === 'Single',
                },
            ]"
        />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import CenterPointIcon from '../../Icon/CenterPointIcon.vue';
import ClothesHangerIcon from '../../Icon/ClothesHangerIcon.vue';
import ConstructionIcon from '../../Icon/ConstructionIcon.vue';
import DangerousIcon from '../../Icon/DangerousIcon.vue';
import DirectionsRunIcon from '../../Icon/DirectionsRunIcon.vue';
import DoorBackIcon from '../../Icon/DoorBackIcon.vue';
import EngineeringIcon from '../../Icon/EngineeringIcon.vue';
import GroupIcon from '../../Icon/GroupIcon.vue';
import InfoIcon from '../../Icon/InfoIcon.vue';
import PackageIcon from '../../Icon/PackageIcon.vue';
import ParkingIcon from '../../Icon/ParkingIcon.vue';
import RestaurantIcon from '../../Icon/RestaurantIcon.vue';
import StoreFrontIcon from '../../Icon/StoreFrontIcon.vue';
import TruckIcon from '../../Icon/TruckIcon.vue';
import WalkingIcon from '../../Icon/WalkingIcon.vue';
import { columnWidthClass } from '../sharedStyles';
import { PhaseForm, PhaseType } from '../Timeline.types';
import {
    getSemiTransparentStrongBackgroundColorAfterClassForType,
    getSemiTransparentStrongBackgroundColorClassForType,
    getStrongBackgroundColorAfterClassForType,
    getStrongBackgroundColorBeforeClassForType,
    getStrongBackgroundColorClassForType,
    getTextColorClassForType,
} from './shared';
import { mapSpecializationsTypesToIcons, SpecializationType } from './SpecializationMappings';

interface AllocationStartProps {
    type: PhaseType;
    form: PhaseForm;
    isPhaseEnd: boolean;
    variant: 'Solid' | 'Deleted' | 'Inserted';
    opacityVariant: 'Solid' | 'Outer-Semi-Transparent' | 'Semi-Transparent';
    specialization: SpecializationType | null;
    hasTemporaryChangeMarker: boolean;
}

const props = defineProps<AllocationStartProps>();

const backgroundColorClass = computed(() => {
    switch (props.variant) {
        case 'Solid':
            return getStrongBackgroundColorClassForType(props.type);

        case 'Deleted':
        case 'Inserted':
            return getSemiTransparentStrongBackgroundColorClassForType(props.type);

        default:
            throw new Error(`Unknown variant ${props.variant}`);
    }
});

const textColorClass = computed(() => getTextColorClassForType(props.type));

const opacityClasses = computed(() => {
    switch (props.opacityVariant) {
        case 'Solid':
            return '';

        case 'Semi-Transparent':
        case 'Outer-Semi-Transparent':
            return 'bg-opacity-30 phase-connector-bar-semi-transparent';
    }
});

const borderClasses = computed(() => {
    if (props.variant === 'Deleted') {
        switch (props.form) {
            case 'Start':
                return 'before:rounded-l-md border-inside border-inside-extended-width border-inside-left border-inside-top border-inside-bottom';

            case 'Middle':
                return 'border-inside border-inside-top border-inside-extended-width border-inside-bottom';

            case 'End':
                return 'before:rounded-r-md border-inside border-inside-normal-width border-inside-top border-inside-right border-inside-bottom';

            case 'Single':
                return 'before:rounded-md border-inside border-inside-normal-width border-inside-left border-inside-top border-inside-right border-inside-bottom';

            default:
                throw new Error(`Unknown form: ${props.form}`);
        }
    }
});

const formClasses = computed(() => {
    switch (props.form) {
        case 'Start':
            return 'rounded-l-md';

        case 'Middle':
            return '';

        case 'End':
            return 'rounded-r-md';

        case 'Single':
            return 'rounded-md';

        default:
            throw new Error(`Unknown form: ${props.form}`);
    }
});

const phaseConnectorBarClasses = computed(() => {
    if (!props.isPhaseEnd && props.form !== 'End' && props.form !== 'Single') {
        if (props.opacityVariant === 'Outer-Semi-Transparent') {
            return `${getStrongBackgroundColorAfterClassForType(
                props.type,
            )} phase-connector-bar ${getStrongBackgroundColorBeforeClassForType(props.type)} phase-connector-bar-inner`;
        }

        switch (props.variant) {
            case 'Solid':
                return `${getStrongBackgroundColorAfterClassForType(props.type)} phase-connector-bar`;

            case 'Deleted':
            case 'Inserted':
                return `${getSemiTransparentStrongBackgroundColorAfterClassForType(props.type)} phase-connector-bar`;

            default:
                throw new Error(`Unknown variant ${props.variant}`);
        }
    }
});

const specializationIcon = computed(() => mapSpecializationsTypesToIcons(props.specialization));
</script>

<style scoped>
.border-inside,
.phase-connector-bar,
.phase-connector-bar-inner {
    position: relative;
}

.border-inside::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 10;
}

.border-inside-normal-width::before {
    width: 100%;
}

.border-inside-extended-width::before {
    width: calc(100% + 1px);
}

.border-inside-left::before {
    border-left: 1px dashed theme('colors.red.700');
}

.border-inside-top::before {
    border-top: 1px dashed theme('colors.red.700');
}

.border-inside-right::before {
    border-right: 1px dashed theme('colors.red.700');
}

.border-inside-bottom::before {
    border-bottom: 1px dashed theme('colors.red.700');
}

.phase-connector-bar::after {
    position: absolute;
    content: '';
    right: -1px;
    width: 1px;
    height: 100%;
    top: 0;
}

.phase-connector-bar-semi-transparent::after {
    @apply bg-opacity-30;
}

.phase-connector-bar-inner::before {
    position: absolute;
    content: '';
    right: -1px;
    width: 1px;
    height: 0.375rem;
    top: 25%;
}
</style>
