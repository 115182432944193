import {
    EventAllocationRoomPhaseCreateInput,
    ProjectAllocationRoomPhaseCreateInput,
    TimelineVariant,
} from '@/components/Timeline/allocation/types';
import { getDurationInDays } from '@/components/Timeline/logic/allocations';
import { AllocationType } from '@/components/Timeline/Timeline.types';
import { UpdateEventVariantAllocationsDocument, UpdateProjectVariantAllocationsDocument } from '@/generated/graphql';
import { useMutation } from '@vue/apollo-composable';
import { Ref, toValue } from 'vue';

export function useCopyAllocations(
    variantsRef: Ref<TimelineVariant[]>,
    setVariantsRef: (value: TimelineVariant[]) => void,
    allocationType: AllocationType,
) {
    const mutations = {
        Event: useMutation(UpdateEventVariantAllocationsDocument),
        Project: useMutation(UpdateProjectVariantAllocationsDocument),
        None: undefined,
    };

    async function onCopyAllocations(data: {
        idTargetVariant: string;
        idSourceVariant: string;
        onDone: (isSuccess: boolean) => void;
    }) {
        const { idTargetVariant, idSourceVariant, onDone } = data;
        const targetVariant = toValue(variantsRef).find((v) => v.id === idTargetVariant);
        if (!targetVariant) throw new Error('Target variant missing for allocation copy');

        const sourceVariant = toValue(variantsRef).find((v) => v.id === idSourceVariant);
        if (!sourceVariant) throw new Error('Source variant missing for allocation copy');

        // Delete existing allocations
        const deleteAllocations = targetVariant.rooms.nodes?.map((n) => ({ id: n.id }));

        // Create new allocations and phases from source variant
        let createRooms: EventAllocationRoomPhaseCreateInput[] | ProjectAllocationRoomPhaseCreateInput[] = [];
        if (allocationType === 'Event') {
            createRooms = sourceVariant.rooms.nodes.map((room) => ({
                roomId: room.roomId,
                start: room.start,
                end: room.end,
                allocationRoomPhasesUsingId: {
                    create: room.phases.nodes.map((phase) => ({
                        usageId: phase.usage.id,
                        specializationType: phase.specializationType,
                        offset: { days: getDurationInDays(phase.offset) },
                        duration: { days: getDurationInDays(phase.duration) },
                    })),
                },
            }));
        } else if (allocationType === 'Project') {
            createRooms = sourceVariant.rooms.nodes.map((room) => ({
                roomId: room.roomId,
                start: room.start,
                end: room.end,
                projectAllocationRoomPhasesUsingId: {
                    create: room.phases.nodes.map((phase) => ({
                        usageId: phase.usage.id,
                        specializationType: phase.specializationType,
                        offset: { days: getDurationInDays(phase.offset) },
                        duration: { days: getDurationInDays(phase.duration) },
                    })),
                },
            }));
        }

        const updateMutation = mutations[allocationType];
        if (updateMutation) {
            const result = await updateMutation.mutate({
                id: targetVariant.id,
                deleteRooms: deleteAllocations,
                createRooms,
            });
            if (result?.errors) {
                onDone(false);
                throw new Error(
                    `Copying allocations from variant "${sourceVariant.name}" to variant "${targetVariant.name}" failed: ${result.errors}`,
                );
            }
            const resultVariant = result?.data?.update?.variant;
            if (resultVariant) {
                setVariantsRef(toValue(variantsRef).map((v) => (v.id === targetVariant.id ? resultVariant : v)));
                onDone(true);
            }
        }
    }

    return {
        onCopyAllocations,
    };
}
