<template>
    <div class="shadow-sm bg-gray-50 flex flex-row min-w-max">
        <div ref="horizontalScrollDetectorRef" />

        <!-- Location pane -->
        <div ref="locationPaneRef" class="border-r-2 border-r-gray-300 outline-none bg-gray-200 sticky left-0 z-20">
            <div ref="verticalScrollDetectorRef" />

            <div ref="firstColumnHeaderRef" class="flex flex-col sticky top-0 z-40">
                <!-- Conflict markers -->
                <!-- Months -->
                <!-- Calendar weeks -->
                <div class="h-[4.4375rem] w-full bg-gray-50" />

                <!-- Site row -->
                <FirstColumnCell
                    :is-site-cell="true"
                    :status="props.siteHasUnresolvedConflicts ? 'has-conflicts-light' : 'ok'"
                    :is-hovered="false"
                    :is-conflict-hovered="false"
                    :has-nested-locations="false"
                    :is-expanded="false"
                    :level="0"
                    :label="props.siteName"
                    :show-resolved-conflicts-icon="props.siteHasConflicts && !props.siteHasUnresolvedConflicts"
                    :show-unresolved-conflicts-icon="props.siteHasUnresolvedConflicts"
                    :unresolved-conflicts-count="props.siteConflictsUnresolvedCount"
                    :error-message="props.siteHasLocations ? undefined : $t('error-no-locations-in-site')"
                    :link-target="props.siteLinkTarget"
                    :checkbox-status="store.siteCheckboxStatus"
                    :checkbox-tooltip="store.siteCheckboxTooltip"
                    @toggle-checkbox="store.toggleSiteCheckbox"
                    @mouseover="hoverTimelinePart(null, null, null, props.siteHasConflicts)"
                >
                    <slot name="site-icon" />
                </FirstColumnCell>
            </div>

            <div class="flex flex-col gap-y-px">
                <!-- External events row -->
                <FirstColumnCell
                    v-if="props.siteHasExternalEvents"
                    :style="props.rowHeightStyle"
                    :is-site-cell="false"
                    status="ok"
                    :is-hovered="hoveredRowId === EXTERNAL_EVENTS_ROW_ID"
                    :is-conflict-hovered="false"
                    :has-nested-locations="false"
                    :is-expanded="false"
                    :level="0"
                    :label="$t('external-events')"
                    :show-resolved-conflicts-icon="false"
                    :show-unresolved-conflicts-icon="false"
                    :unresolved-conflicts-count="0"
                    :link-target="props.externalEventsLinkTarget"
                    checkbox-status="No-Checkbox"
                    @mouseover="hoverTimelinePart(null, EXTERNAL_EVENTS_ROW_ID, null, false)"
                >
                    <AirplaneIcon :title="$t('external-events')" aria-hidden="true" />
                </FirstColumnCell>

                <template v-for="section in store.sectionsWithCheckboxes">
                    <!-- Section rows -->
                    <SectionRow
                        v-if="section.hasLabel"
                        :key="section.id"
                        :label="section.label"
                        :is-expanded="section.isExpanded"
                        :status="section.status"
                        :is-hovered="hoveredSection === section.id"
                        :unresolved-conflicts-count="section.unresolvedConflictsCount"
                        :allocation-count="section.allocationCount"
                        :checkbox-status="section.checkboxStatus"
                        :checkbox-tooltip="section.checkboxTooltip"
                        @section-hover="hoverTimelinePart(section.id, null, null, false)"
                        @toggle-section-expanded="
                            store.hasCheckboxes ? undefined : $emit('toggle-section-expanded', section.id)
                        "
                        @toggle-checkbox="() => store.toggleSectionCheckbox(section.id)"
                    >
                        <Component :is="section.icon" class="w-5 h-5" :title="section.iconTitle" aria-hidden="true" />
                    </SectionRow>

                    <!-- Locations rows -->
                    <template v-for="location in section.locations">
                        <FirstColumnCell
                            v-if="'isVisible' in location && location.isVisible"
                            :key="location.id"
                            :style="props.rowHeightStyle"
                            :is-site-cell="false"
                            :status="location.status"
                            :is-hovered="hoveredRowId === location.rowId"
                            :is-conflict-hovered="isConflictCellHovered"
                            :has-nested-locations="location.hasNestedLocations"
                            :is-expanded="location.isExpanded"
                            :level="location.level"
                            :label="location.label"
                            :show-resolved-conflicts-icon="location.showResolvedConflictsIcon"
                            :show-unresolved-conflicts-icon="location.showUnresolvedConflictsIcon"
                            :unresolved-conflicts-count="location.conflictsUnresolvedCount"
                            :error-message="
                                location.areNestedLocationsMissing ? $t('error-nested-locations-missing') : undefined
                            "
                            :link-target="location.linkTarget"
                            :checkbox-status="location.checkboxStatus"
                            :checkbox-tooltip="location.checkboxTooltip"
                            @key-press="$emit('key-press', location.id, $event)"
                            @toggle-location-expanded="$emit('toggle-location-expanded', location.id)"
                            @mouseover="
                                hoverTimelinePart(null, location.rowId, null, location.conflictsUnresolvedCount > 0)
                            "
                            @toggle-checkbox="() => store.toggleLocationCheckbox(location.id)"
                        >
                            <Component
                                :is="location.icon"
                                class="w-5 h-5"
                                :title="location.iconTitle"
                                aria-hidden="true"
                            />
                        </FirstColumnCell>
                    </template>
                </template>
            </div>
        </div>

        <!-- Previous period pane -->
        <div v-if="props.previousPeriodLabel" class="relative z-10 flex items-stretch">
            <PreviousNextPeriodColumn
                icon-direction="left"
                :label="props.previousPeriodLabel"
                :is-disabled="props.isPreviousPeriodDisabled"
                @click="$emit('expand-to-previous-month')"
                @mouseover="hoverTimelinePart(null, null, null, false)"
            />
        </div>

        <!-- Days pane -->
        <div class="focus:ring-offset-0 focus:ring-blue-700 focus:ring-2 relative z-10 flex flex-col">
            <TimelineHeader
                ref="timelineHeaderRef"
                class="flex flex-col sticky top-0 z-40"
                :days-count="props.header.days.length"
            >
                <!-- Conflict markers -->
                <HeaderConflictDay
                    v-for="day in props.header.days"
                    :key="day.columnId"
                    :conflict-status="day.conflictStatus"
                    :formatted-date="day.formattedDate"
                    @mouseover="hoverTimelinePart(null, null, null, false)"
                />

                <!-- Months -->
                <HeaderMonth
                    v-for="month in props.header.months"
                    :key="month.startColumnIndex"
                    :days-count="month.daysCount"
                    :label="month.label"
                    :start-column-index="month.startColumnIndex"
                    @mouseover="hoverTimelinePart(null, null, null, false)"
                />

                <!-- Calendar weeks -->
                <HeaderCalendarWeek
                    v-for="(calendarWeek, calendarWeekIndex) in props.header.calendarWeeks"
                    :key="calendarWeek.startColumnIndex"
                    :days-count="calendarWeek.daysCount"
                    :is-last-calendar-week="calendarWeekIndex === props.header.calendarWeeks.length - 1"
                    :label="calendarWeek.label"
                    :start-column-index="calendarWeek.startColumnIndex"
                    @mouseover="hoverTimelinePart(null, null, null, false)"
                />

                <!-- Header days -->
                <HeaderDay
                    v-for="day in header.days"
                    :key="day.columnId"
                    :is-work-day="day.isWorkDay"
                    :is-column-hovered="hoveredColumnId === day.columnId"
                    :is-conflict-cell-hovered="isConflictCellHovered"
                    :day-number="day.number"
                    :day-full-name="day.fullName"
                    :day-abbreviation="day.abbreviation"
                    :is-first-column="day.isFirstColumn"
                    @mouseover="hoverTimelinePart(null, null, day.columnId, day.conflictStatus !== 'no-conflict')"
                />
            </TimelineHeader>

            <TimelineBody
                v-if="props.siteHasExternalEvents || props.siteHasLocations"
                :days-count="props.header.days.length"
                :grid-rows="gridRows"
            >
                <!-- External events -->
                <TimelineRow
                    v-if="props.externalEvents.length > 0"
                    :is-first-row="true"
                    :days-count="props.header.days.length"
                >
                    <Cell
                        v-for="day in props.externalEvents"
                        :key="`${day.columnId}-${day.rowId}`"
                        :style="props.rowHeightStyle"
                        :is-in-hovered-row="hoveredRowId === day.rowId"
                        :is-in-hovered-column="hoveredColumnId === day.columnId"
                        :is-conflict-hovered="isConflictCellHovered"
                        :is-work-day="day.isWorkDay"
                        :is-in-first-column="day.isFirstColumn"
                        :is-in-first-row="true"
                        :allocations="day.allocations"
                        :conflict-marker="'no-conflict'"
                        :has-create-button="false"
                        allocation-variant-id="external-event"
                        location-id="external-event"
                        :is-location-inside="true"
                        :start-date-time="day.startDateTime"
                        :end-date-time="day.endDateTime"
                        :current-allocation-variant-label="null"
                        @mouseover="hoverTimelinePart(null, day.rowId, day.columnId, false)"
                        @allocation-mouseover="hoverTimelinePart(null, day.rowId, null, false)"
                    />
                </TimelineRow>

                <template v-for="section in store.sectionsWithCheckboxes">
                    <!-- Section rows -->
                    <CellPlaceholder
                        v-if="section.hasLabel"
                        :key="section.id"
                        :label="section.label"
                        :days-count="props.header.days.length"
                        :is-hovered="hoveredSection === section.id"
                        :status="section.status"
                        @mouseover="hoverTimelinePart(section.id, null, null, false)"
                        @toggle-section-expanded="
                            store.hasCheckboxes ? undefined : $emit('toggle-section-expanded', section.id)
                        "
                    />

                    <TimelineRow
                        v-for="timelineRow in section.rows"
                        :key="timelineRow.locationId"
                        :is-first-row="false"
                        :days-count="props.header.days.length"
                    >
                        <!-- Days -->
                        <Cell
                            v-for="day in timelineRow.days"
                            :key="`${day.columnId}-${day.rowId}`"
                            :style="props.rowHeightStyle"
                            :is-in-hovered-row="hoveredRowId === day.rowId"
                            :is-in-hovered-column="hoveredColumnId === day.columnId"
                            :is-conflict-hovered="isConflictCellHovered"
                            :is-work-day="day.isWorkDay"
                            :is-in-first-column="day.isFirstColumn"
                            :is-in-first-row="props.externalEvents.length === 0 && day.isFirstRow"
                            :allocations="day.allocations"
                            :conflict-marker="day.conflictMarker"
                            :has-create-button="day.hasCreateButton"
                            :allocation-variant-id="props.allocationVariantId"
                            :location-id="timelineRow.locationId"
                            :is-location-inside="timelineRow.isLocationInside"
                            :start-date-time="day.startDateTime"
                            :end-date-time="day.endDateTime"
                            :current-allocation-variant-label="props.currentAllocationVariantLabel"
                            @mouseover="
                                hoverTimelinePart(null, day.rowId, day.columnId, day.conflictMarker !== 'no-conflict')
                            "
                            @allocation-mouseover="
                                hoverTimelinePart(null, day.rowId, null, day.conflictMarker !== 'no-conflict')
                            "
                        />
                    </TimelineRow>
                </template>
            </TimelineBody>
        </div>

        <!-- Next period pane -->
        <div v-if="props.nextPeriodLabel" class="relative z-10 flex items-stretch">
            <PreviousNextPeriodColumn
                icon-direction="right"
                :label="props.nextPeriodLabel"
                :is-disabled="props.isNextPeriodDisabled"
                @click="$emit('expand-to-next-month')"
                @mouseover="hoverTimelinePart(null, null, null, false)"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useElementSize, useIntersectionObserver } from '@vueuse/core';
import { computed, onUnmounted, reactive, ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import AirplaneIcon from '../../Icon/AirplaneIcon.vue';
import { useTimelineStore } from '../Timeline.store';
import type {
    TimelineExternalEventDay,
    TimelineHeaderCalendarWeek,
    TimelineHeaderDay,
    TimelineHeaderMonth,
} from '../TimelineView.types';
import { EXTERNAL_EVENTS_ROW_ID } from '../logic/externalEvents';
import Cell from './Cell.vue';
import CellPlaceholder from './CellPlaceholder.vue';
import FirstColumnCell from './FirstColumnCell.vue';
import HeaderCalendarWeek from './HeaderCalendarWeek.vue';
import HeaderConflictDay from './HeaderConflictDay.vue';
import HeaderDay from './HeaderDay.vue';
import HeaderMonth from './HeaderMonth.vue';
import PreviousNextPeriodColumn from './PreviousNextPeriodColumn.vue';
import SectionRow from './SectionRow.vue';
import TimelineBody from './TimelineBody.vue';
import TimelineHeader from './TimelineHeader.vue';
import TimelineRow from './TimelineRow.vue';

type TimelineViewProps = {
    siteName: string;
    siteLinkTarget?: RouteLocationRaw;
    siteConflictsUnresolvedCount: number;
    siteHasConflicts: boolean;
    siteHasUnresolvedConflicts: boolean;
    siteHasLocations: boolean;
    siteHasExternalEvents: boolean;
    externalEvents: TimelineExternalEventDay[];
    externalEventsLinkTarget?: RouteLocationRaw;
    header: {
        days: TimelineHeaderDay[];
        calendarWeeks: TimelineHeaderCalendarWeek[];
        months: TimelineHeaderMonth[];
    };
    previousPeriodLabel: string | null;
    isPreviousPeriodDisabled: boolean;
    nextPeriodLabel: string | null;
    isNextPeriodDisabled: boolean;
    rowHeightStyle: { height: string };
    allocationVariantId: string;
    currentAllocationVariantLabel: string | null;
};

const props = defineProps<TimelineViewProps>();

defineEmits<{
    (e: 'toggle-location-expanded', locationId: string): void;
    (e: 'toggle-section-expanded', sectionId: string): void;
    (e: 'key-press', locationId: string, event: KeyboardEvent): void;
    (e: 'expand-to-previous-month'): void;
    (e: 'expand-to-next-month'): void;
}>();

const store = useTimelineStore();

const hoveredSection = ref<string | null>(null);
const hoveredRowId = ref<string | null>(null);
const hoveredColumnId = ref<string | null>(null);
const isConflictCellHovered = ref(false);

function hoverTimelinePart(
    sectionId: string | null,
    rowId: string | null,
    columnId: string | null,
    isConflictCell: boolean,
) {
    hoveredSection.value = sectionId;
    hoveredRowId.value = rowId;
    hoveredColumnId.value = columnId;
    isConflictCellHovered.value = isConflictCell;
}

const gridRows = computed<('cell' | 'placeholder')[]>(() => {
    const rows: ('cell' | 'placeholder')[] = [];

    store.sectionsWithCheckboxes.forEach((section) => {
        if (section.hasLabel) {
            rows.push('placeholder');
        }

        section.locations.forEach((location) => {
            if ('isVisible' in location && location.isVisible) {
                rows.push('cell');
            }
        });
    });

    if (props.siteHasExternalEvents) {
        return ['cell', ...rows];
    }

    return rows;
});

const locationPaneRef = ref<HTMLElement | null>(null);
const verticalScrollDetectorRef = ref<HTMLElement | null>(null);
const horizontalScrollDetectorRef = ref<HTMLElement | null>(null);
const firstColumnHeaderRef = ref<HTMLElement | null>(null);
const timelineHeaderRef = ref();

const { stop: stopVerticalScrollDetector } = useIntersectionObserver(
    verticalScrollDetectorRef,
    ([args]) => {
        if (args.isIntersecting) {
            firstColumnHeaderRef.value?.classList.remove('scroll-shadow-vertical');
            timelineHeaderRef.value?.ref.classList.remove('scroll-shadow-vertical');
        } else {
            firstColumnHeaderRef.value?.classList.add('scroll-shadow-vertical');
            timelineHeaderRef.value?.ref.classList.add('scroll-shadow-vertical');
        }
    },
    {},
);

const { stop: stopHorizontalScrollDetector } = useIntersectionObserver(
    horizontalScrollDetectorRef,
    ([args]) => {
        if (args.isIntersecting) {
            locationPaneRef.value?.classList.remove('scroll-shadow-horizontal');
        } else {
            locationPaneRef.value?.classList.add('scroll-shadow-horizontal');
        }
    },
    {},
);

onUnmounted(() => {
    stopVerticalScrollDetector();
    stopHorizontalScrollDetector();
});

const headerSize = reactive(useElementSize(timelineHeaderRef));
const headerHeight = computed(() => `${headerSize.height}px`);
const locationPaneSize = reactive(useElementSize(locationPaneRef));
const locationPaneWidth = computed(() => `${locationPaneSize.width}px`);
</script>

<style>
.scroll-shadow-horizontal::after {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    left: v-bind('locationPaneWidth');
    top: 0;
    width: v-bind('locationPaneWidth');
    box-shadow: 10px 0 4px -2px theme('colors.gray.900/0.2') inset;
    z-index: 20;
    pointer-events: none;
}

.scroll-shadow-vertical::before {
    content: '';
    display: block;
    height: v-bind('headerHeight');
    position: absolute;
    left: 0;
    top: v-bind('headerHeight');
    width: 100%;
    box-shadow: 0 10px 4px -2px theme('colors.gray.900/0.2') inset;
    z-index: 20;
    pointer-events: none;
}
</style>
