import { DateTime } from 'luxon';
import { InjectionKey, Ref } from 'vue';
import { AllocationPhaseUpdate, AllocationType } from './Timeline.types';
import { AllocationPhaseInput } from './allocation/types';

// This follows the recommendation for typing Provide/Inject: https://vuejs.org/guide/typescript/composition-api.html#typing-provide-inject
export const triggerTooltipPositioningKey = Symbol() as InjectionKey<Ref<number>>;

export const emitCreateAllocationKey = Symbol() as InjectionKey<
    (
        locationId: string,
        startDateTime: DateTime,
        endDateTime: DateTime,
        allocationVariantId: string,
        phases: AllocationPhaseInput[],
        onDone: (isSuccess: boolean) => void,
    ) => void
>;

export const emitDeleteAllocationKey = Symbol() as InjectionKey<
    (allocationId: string, onDone: (isSuccess: boolean) => void) => void
>;

export const emitUpdatePhasesKey = Symbol() as InjectionKey<
    (
        phases: AllocationPhaseUpdate[],
        initialStartDateTime: DateTime,
        initialEndDateTime: DateTime,
        newLocationIds: string[],
        allocationId: string,
        allocationType: AllocationType,
        allocationVariantId: string,
        onDone: (isSuccess: boolean, phaseUpdates: AllocationPhaseUpdate[]) => void,
    ) => void
>;
