import { ComboboxItem } from '@/components/Form/ComboboxField.vue';
import { FormErrors } from '@/components/Form/types';
import { FormLink, FormMultilink, Multilink } from '@/components/MultiLink/types';
import { IntervalInput } from '@/generated/graphql';
import { z } from 'zod';
import { EventCreateSchema } from './forms/schemas';

export const eventSeriesStates = ['active', 'passive', 'discontinued'] as const;
export type EventSeriesStatus = (typeof eventSeriesStates)[number];

export const eventStates = [
    'active-idea',
    'active-option',
    'active-fix',
    'passive',
    'discontinued',
    'completed',
    'canceled',
] as const;
export type EventStatus = (typeof eventStates)[number];

export const eventVariantStates = ['active-1', 'active-2', 'passive', 'discontinued'] as const;
export type EventVariantStatus = (typeof eventVariantStates)[number];

export type SeriesDetailsEvent = {
    id: string;
    nameShort: string;
    status: EventStatus;
    mainVariant: {
        nodes: [
            {
                id: string;
                name: string;
                start: string;
                end: string;
            },
        ];
    };
};

export type EventSeriesDetails = {
    [key: string]: string | number | boolean | object | SeriesDetailsEvent[] | undefined | null | Multilink;
    id: string;
    nameShort: string;
    nameLong: string;
    subtitle: string;
    status: EventSeriesStatus;
    numberShort: string;
    foundationYear?: number;
    notes: string;
    description: string;
    category: string;
    type: string;
    isTechnical?: boolean;
    isPublic?: boolean;
    partnersOverviewName: string;
    partnersOverviewLink: string;
    organizers?: Multilink;
    executors?: Multilink;
    sponsors?: Multilink;
    extraDocuments?: Multilink;
    extraSystems?: Multilink;
    externalData?: Multilink;
    internalData?: Multilink;
    subeventsLocal?: Multilink;
    subeventsOtherLocations?: Multilink;
    fairAbroad?: Multilink;
    fairAbroadCooperation?: Multilink;
    competitionNational?: Multilink;
    otherCompetitionNational?: Multilink;
    competitionInternational?: Multilink;
    otherCompetitionInternational?: Multilink;
    favoredParallelsInternal?: Multilink;
    favoredParallelsExternal?: Multilink;
    otherLinksInternal?: Multilink;
    otherLinksExternal?: Multilink;
    additionalPartners: string;
    companyGroup: string;
    businessDomain: string;
    department: string;
    organisationalChanges: string;
    notesDayOrder: string;
    notesSetup: string;
    notesEarlyConstructionInternal: string;
    notesEarlyConstructionExternal: string;
    notesConstruction: string;
    notesExecution: string;
    notesDismantling: string;
    notesExtendedDismantlingExternal: string;
    notesExtendedDismantlingInternal: string;
    notesTechnicalDismantling: string;
    notesDayOrderHistory: string;
    notesAppointmentRules: string;
    notesMonths: string;
    notesHolidaysNrw: string;
    notesHolidaysNational: string;
    notesHolidaysInternational: string;
    notesPublicHolidaysNrw: string;
    notesPublicHolidaysNational: string;
    notesPublicHolidaysInternational: string;
    otherNotesAppointmentRules: string;
    appointmentHistory: string;
    cycle: string;
    cycleChanges: string;
    allocationRules: string;
    locationHistory: string;
    otherAllocationNeeds: string;
    notesHallExhibitionAreas: string;
    notesBoulevardSections: string;
    notesPassages: string;
    notesEntrances: string;
    notesServiceCenters: string;
    notesConferenceRooms: string;
    notesOfficesInternal: string;
    notesStorageInternal: string;
    notesOfficesExternal: string;
    notesStorageExternal: string;
    notesAreas: string;
    notesParking: string;
    iconUpload: string;
    events?: {
        nodes: SeriesDetailsEvent[];
    };
    icon?: {
        original: string;
    };
    singleEvent: boolean;
};
export type EventSeriesDetailsErrors = FormErrors<EventSeriesDetails>;

export type EventSeriesMultiLinks = {
    organizers: FormMultilink;
    executors: FormMultilink;
    sponsors: FormMultilink;
    extraDocuments: FormMultilink;
    extraSystems: FormMultilink;
    externalData: FormMultilink;
    internalData: FormMultilink;
    subeventsLocal: FormMultilink;
    subeventsOtherLocations: FormMultilink;
    fairAbroad: FormMultilink;
    fairAbroadCooperation: FormMultilink;
    competitionNational: FormMultilink;
    otherCompetitionNational: FormMultilink;
    competitionInternational: FormMultilink;
    otherCompetitionInternational: FormMultilink;
    favoredParallelsInternal: FormMultilink;
    favoredParallelsExternal: FormMultilink;
    otherLinksInternal: FormMultilink;
    otherLinksExternal: FormMultilink;
};

export type UpdateEventSeriesMultilinkParams = {
    [key: string]: string | undefined;
    id: string;
    organizersId?: string;
    executorsId?: string;
    sponsorsId?: string;
    extraDocumentsId?: string;
    extraSystemsId?: string;
    externalDataId?: string;
    internalDataId?: string;
    subeventsLocalId?: string;
    subeventsOtherLocationsId?: string;
    fairAbroadId?: string;
    fairAbroadCooperationId?: string;
    competitionNationalId?: string;
    otherCompetitionNationalId?: string;
    competitionInternationalId?: string;
    otherCompetitionInternationalId?: string;
    favoredParallelsInternalId?: string;
    favoredParallelsExternalId?: string;
    otherLinksInternalId?: string;
    otherLinksExternalId?: string;
};

export type EventMultiLinks = {
    extraDocuments: FormMultilink;
    extraSystems: FormMultilink;
    externalData: FormMultilink;
    internalData: FormMultilink;
    subeventsLocal: FormMultilink;
    subeventsOtherLocations: FormMultilink;
    fairAbroad: FormMultilink;
    fairAbroadCooperation: FormMultilink;
    competitionNational: FormMultilink;
    otherCompetitionNational: FormMultilink;
    competitionInternational: FormMultilink;
    otherCompetitionInternational: FormMultilink;
    favoredParallelsInternal: FormMultilink;
    favoredParallelsExternal: FormMultilink;
    otherLinksInternal: FormMultilink;
    otherLinksExternal: FormMultilink;
};

export type UpdateEventMultilinkParams = {
    [key: string]: string | undefined;
    id: string;
    extraDocumentsId?: string;
    extraSystemsId?: string;
    externalDataId?: string;
    internalDataId?: string;
    subeventsLocalId?: string;
    subeventsOtherLocationsId?: string;
    fairAbroadId?: string;
    fairAbroadCooperationId?: string;
    competitionNationalId?: string;
    otherCompetitionNationalId?: string;
    competitionInternationalId?: string;
    otherCompetitionInternationalId?: string;
    favoredParallelsInternalId?: string;
    favoredParallelsExternalId?: string;
    otherLinksInternalId?: string;
    otherLinksExternalId?: string;
};

export type EventUpdate = {
    [key: string]: string | number | object | undefined | boolean;
    id: string;
    nameShort: string;
    nameLong: string;
    subtitle: string;
    executionYear?: number;
    description: string;
    annualSpecifics: string;
    companyGroup: string;
    businessDomain: string;
    department: string;
    responsible: string;
    assistant: string;
    teamChanges: string;
    status: EventStatus;
    iconUpload: string;
    icon?: {
        original: string;
    };
    series?: {
        id: string;
        nameShort: string;
        icon?: { original: string };
        singleEvent: boolean;
    };
    extraDocuments?: Multilink;
    extraSystems?: Multilink;
    externalData?: Multilink;
    internalData?: Multilink;
    subeventsLocal?: Multilink;
    subeventsOtherLocations?: Multilink;
    fairAbroad?: Multilink;
    fairAbroadCooperation?: Multilink;
    competitionNational?: Multilink;
    otherCompetitionNational?: Multilink;
    competitionInternational?: Multilink;
    otherCompetitionInternational?: Multilink;
    favoredParallelsInternal?: Multilink;
    favoredParallelsExternal?: Multilink;
    otherLinksInternal?: Multilink;
    otherLinksExternal?: Multilink;
};

export type EventUpdateErrors = FormErrors<EventUpdate>;

export type EventDetails = {
    [key: string]: string | number | object | undefined | null;
    id: string;
    nameShort: string;
    nameLong: string;
    subtitle: string;
    executionYear?: number | null;
    description: string;
    annualSpecifics: string;
    companyGroup: string;
    businessDomain: string;
    department: string;
    responsible: string;
    assistant: string;
    teamChanges: string;
    status: EventStatus;
    mainVariant: {
        nodes: Array<{
            id: string;
            start?: string | null;
            end?: string | null;
            version: number;
        }>;
    };
    series?: {
        id: string;
        nameShort: string;
        icon?: { original: string } | null;
    } | null;
    icon?: {
        original: string;
    } | null;
};

export type EmitMultilinkField = { input: { event: Event; link: FormLink; fieldName: string }; field: string };
export type EmitDeleteLink = { link: FormLink; fieldName: string };
export type EmitSelectField = { item: ComboboxItem | null; fieldName: string };
export type EmitFormField = { event: Event; fieldName: string };
export type EmitToggleField = { value: boolean; fieldName: string };

export type EventCreateSchemaType = z.infer<typeof EventCreateSchema>;

export type EventCreateType = {
    [key: string]: string | number | undefined;
    nameShort: string;
    nameLong: string;
    executionYear?: number;
    status: EventStatus;
};
export type EventCreateErrors = FormErrors<EventCreateType>;

export type AllocationResultPhase = {
    id: string;
    duration: IntervalInput;
    usage: {
        id: string;
        name: string;
    };
    specializationType: string;
};

export type TimelineDataAllocationResult = {
    id: string;
    roomId: string;
    variant: {
        id: string;
        name: string;
    };
    start: string;
    end: string;
    phases: {
        nodes: AllocationResultPhase[];
    };
};
