import { AllocationUsage, TimelineVariant } from '@/components/Timeline/allocation/types';
import {
    GetEventDataForTimelineDocument,
    TimelineEventFragment,
    TimelineRoomFragment,
    TimelineSiteFragment,
} from '@/generated/graphql';
import { useQuery } from '@vue/apollo-composable';
import { computed, Ref, ref, toValue } from 'vue';

export function useEventTimelineData(projectId: Ref<string>) {
    const variantsRef: Ref<TimelineVariant[]> = ref([]);
    const event: Ref<TimelineEventFragment | undefined> = ref();
    const rooms: Ref<TimelineRoomFragment[]> = ref([]);
    const site: Ref<TimelineSiteFragment | undefined> = ref();
    const usages: Ref<AllocationUsage[]> = ref([]);

    const { loading, error, onResult } = useQuery(
        GetEventDataForTimelineDocument,
        computed(() => ({ eventId: toValue(projectId) })),
    );

    onResult((result) => {
        if (result.error) {
            throw new Error('Error on receiving project timeline data');
        }
        const data = result.data;
        if (data) {
            event.value = data.event ?? undefined;
            const variants = data.event?.variants.nodes;
            if (variants) {
                variantsRef.value = variants;
            }
            rooms.value = data.rooms?.nodes ?? [];
            const sites = data.sites?.nodes ?? [];
            if (!sites) throw new Error(`Missing site for event: ${toValue(projectId)}`);
            site.value = sites[0];
            usages.value = data.usages?.nodes ?? [];
        }
    });

    function setVariantsRef(value: TimelineVariant[]) {
        variantsRef.value = value;
    }

    return { variantsRef, setVariantsRef, loading, error, onResult, site, event, rooms, usages };
}
